import * as type from "./mutation-types";

// Change state data
const mutations = {
  [type.SET_AUTHENTICATED](state, payload) {
    state.authenticated = payload;
  },
  [type.SET_USER](state, user = null) {
    state.user = user;
  }
};

export default mutations;
