// Change state data
import * as type from "./mutation-types";

const mutations = {
  updateSystemLoading(state, payload) {
    state.system = payload;
  },
  [type.SET_PAGE_LOADING](state, loading) {
    state.page = loading;
  }
};

export default mutations;
