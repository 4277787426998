/* eslint-disable no-unused-vars */
import * as type from "./mutation-types";
import { authHelper } from "@/helpers/classes/auth.js";
import {
  ModeratorUserSchema,
  BusinessUserSchema,
  InfluencerUserSchema,
  AgencyUserSchema,
} from "@/helpers/schemas/core/users.js";
import {
  LoginSchema,
  RegisterInfluencerSchema,
  registerBusinessSchema,
  RefreshTokenSchema,
  loginTokenSchema,
  ForgotPasswordSchema,
  ShadowLoginSchema,
} from "@/helpers/schemas/core/auth.js";

// Methods. NEVER update state data from an action/method
// Update state data with mutations
const auth = authHelper();

const actions = {
  setUser({ commit }, user) {
    commit(type.SET_USER, user);
  },
  getUserFromApi({ dispatch }) {
    const uuid = auth.getUserId();
    const userType = auth.getUserType();
    let userSchema = null;
    if (userType === "Moderator") {
      userSchema = ModeratorUserSchema;
    } else if (userType === "Business") {
      userSchema = BusinessUserSchema;
    } else if (userType === "Influencer") {
      userSchema = InfluencerUserSchema;
    } else if (userType === "Agency") {
      userSchema = AgencyUserSchema;
    }
    const data = {
      params: { uuid: uuid },
      result: userSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("user", data).then(
        (response) => {
          const user = response.data.user;
          if (userType === "Moderator") {
            user.uuid = user.id;
          }
          dispatch("setUser", user);
          resolve(user);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  login({ commit }, params) {
    const data = {
      params: params,
      result: LoginSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("login", data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  shadowLogin({ commit }, params) {
    const data = {
      params: params,
      result: ShadowLoginSchema,
      type: "ShadowLogin",
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("shadowLogin", data).then(
        (response) => {
          resolve(response.data.shadowLogin);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  forgotPassword({ commit }, params) {
    const data = {
      params: params,
      result: ForgotPasswordSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("forgotPassword", data).then(
        (response) => {
          resolve(response.data.forgotPassword);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  registerInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: RegisterInfluencerSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("registerInfluencer", data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  registerBusiness({ commit }, params) {
    const data = {
      params: params,
      result: registerBusinessSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("registerBusiness", data).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  refreshToken({ commit }, params) {
    const data = {
      params: params,
      result: RefreshTokenSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("refreshToken", data).then(
        (response) => {
          resolve(response.data.refreshToken);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  loginToken({ commit }, params) {
    const data = {
      params: params,
      result: loginTokenSchema,
      type: "LoginTokenPayload",
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("loginToken", data).then(
        (response) => {
          resolve(response.data.loginToken);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  checkAuth({ commit }) {
    const data = {
      params: null,
      result: "{ status }",
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("check_auth", data).then(
        (response) => {
          resolve(response.data.check_auth);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  setAuth({ commit }, user) {
    commit(type.SET_AUTHENTICATED, true);
    commit(type.SET_USER, user);
  },
  logout({ commit }) {
    auth.destroyTokens();
    commit(type.SET_AUTHENTICATED, false);
    commit(type.SET_USER, null);
  },
};

export default actions;
