<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="!missingChannels" step="1"
        >Connect media</v-stepper-step
      >
      <v-divider></v-divider>

      <v-stepper-step :complete="!noChannelsApproved" step="2"
        >Approval</v-stepper-step
      >
      <v-divider></v-divider>

      <v-stepper-step :complete="!!user.stripe_connect_key" step="3"
        >Account type</v-stepper-step
      >
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <step1 @next="step = 2"></step1>
      </v-stepper-content>

      <v-stepper-content step="2">
        <step2 @previous="step = 1" @next="step = 3"></step2>
      </v-stepper-content>

      <v-stepper-content step="3">
        <step3 @previous="step = 1" @next="step = 3"></step3>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";

import { mapGetters, mapState } from "vuex";
export default {
  components: {
    Step1,
    Step2,
    Step3
  },
  data: () => ({
    step: 1
  }),
  computed: {
    ...mapGetters("core/auth", [
      "missingChannels",
      "noChannelsApproved"
    ]),
    ...mapState("core/auth", ["user"])
  },
  created() {
    if (this.missingChannels) {
      this.step = 1;
    } else if (this.noChannelsApproved) {
      this.step = 2;
    } else {
      this.step = 3;
    }
  }
};
</script>

<style scoped></style>
