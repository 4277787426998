export const CreateSaleSchema = `{
    uuid
    value
    unique_key
    commission
    params
    created_at
    updated_at
    approved_at
    disapproved_at
    paid_at
    
    influencer{
      uuid
      user{
        uuid
        full_name
      }
    }
    business{
        uuid
        company
        display_name
        user{
            uuid
            full_name
          }
          campaigns{
            uuid
            name
            active
            utm_campaign
            utm_medium
            description
          }
    }
    click{
      uuid
      unique_key
      created_at
      updated_at
      campaign{
          uuid
          name
          business{
              uuid
              display_name
          }
      }
      influencer_channel{
          uuid
          username
          description
          influencer{
              uuid
              birthday
              gender
              user{
                  uuid
                  email
                  first_name
                  last_name
                  full_name

              }
          }
          channel{
              uuid
              name
              display_name
              description
              icon
              created_at
              updated_at
          }
      }
  }
  promotion_code{
      uuid
      code
      description
      business{
          uuid
          display_name
      }
      influencer{
          uuid
          birthday
          gender
          user{
              uuid
              email
              first_name
              last_name
              full_name

          }
      }
  }
}`;
export const ModeratorSalesSchema = `{
    response{
        uuid
        unique_key
        ip
        value
        original_value
        original_currency
        exchange_rate
        exchange_rate_updated_at
        commission
        params
        approved_at
        disapproved_at
        disapproved_note
        paid_at
        payout_at
        stripe_invoice_key
        stripe_transfer_key
        click_type
        created_at
        updated_at
        influencer{
            uuid
            user{
                uuid
                email
                first_name
                last_name
                full_name
            }
        }
        business{
            uuid
            company
            display_name
        }
        click{
            uuid
            unique_key
            created_at
            updated_at
            ip
            campaign{
                uuid
                name
                business{
                    uuid
                    display_name
                }
            }
            influencer_channel{
                uuid
                username
                description
                influencer{
                    uuid
                    birthday
                    gender
                    stripe_updated_at
                    stripe_connect_key
                    stripe_balance_available
                    user{
                        uuid
                        email
                        first_name
                        last_name
                        full_name
                    }
                }
                channel{
                    uuid
                    name
                    display_name
                    description
                    icon
                    created_at
                    updated_at
                }
            }
            http_link{
                link
            }
            user_agent{
                id
            }
        }
        promotion_code{
            code
            uuid
            description
            business{
                uuid
                display_name
            }
            influencer{
                uuid
                user{
                    uuid
                    email
                    first_name
                    last_name
                    full_name
                }
            }
        }
        user_agent{
            id
        }
        http_referer{
            id
        }
        ip_information{
            id
        }
    }
    summary {
        total
        approved
        paid
        invoiced
        disapproved
      }
    lastPage
    currentPage
    total
    perPage
    exportLink
}`;
export const SalesSchema = `{
response{
        uuid
        unique_key
        ip
        value
        original_value
        original_currency
        exchange_rate
        exchange_rate_updated_at
        commission
        params
        approved_at
        disapproved_at
        disapproved_note
        paid_at
        payout_at
        stripe_invoice_key
        stripe_transfer_key
        click_type
        created_at
        updated_at
        influencer{
            uuid
            user{
                uuid
                email
                first_name
                last_name
                full_name
            }
        }
        business{
            uuid
            company
            display_name
        }
        click{
            uuid
            unique_key
            created_at
            updated_at
            campaign{
                uuid
                name
                business{
                    uuid
                    display_name
                }
            }
            influencer_channel{
                uuid
                username
                description
                influencer{
                    uuid
                    birthday
                    gender
                    stripe_updated_at
                    stripe_connect_key
                    stripe_balance_available
                    user{
                        uuid
                        email
                        first_name
                        last_name
                        full_name
                    }
                }
                channel{
                    uuid
                    name
                    display_name
                    description
                    icon
                    created_at
                    updated_at
                }
            }
            http_link{
                link
            }
            user_agent{
                id
            }
        }
        promotion_code{
            code
            uuid
            description
            business{
                uuid
                display_name
            }
            influencer{
                uuid
                user{
                    uuid
                    email
                    first_name
                    last_name
                    full_name
                }
            }
        }
        user_agent{
            id
        }
        http_referer{
            id
        }
        ip_information{
            id
        }
    }
    summary {
        total
        approved
        paid
        invoiced
        disapproved
      }
    lastPage
    currentPage
    total
    perPage
    exportLink
}`;
