export const ConversionLogsPaginatedSchema = `{
    lastPage
    currentPage
    total
    limit
    data {
      id
      type
      created_at
      body
      request {
        ip
        cookie_id
        http_referer
        user_agent {
          name
          device
          operating_system
        }
      }
      conversionData {
        ip
        cookie_id
        http_referer
        user_agent {
          name
          device
          operating_system
        }
        created_at
        business {
          uuid
          display_name
        }
        unique_id
        value
        currency
        promotion_code
      }
    }
}`;