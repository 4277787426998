export const PromotionCodeSchema = `{
    uuid
    code
    description
    cost_per_action_fixed
    cost_per_action_percent
    created_at
    updated_at
    deleted_at
    influencer{
        uuid
        user{
            uuid
            first_name
            last_name
            avatar
        }
        channels{
            profile_image_url
        }
    }
}`;
