import { authHelper } from "../classes/auth";

const auth = authHelper();

export default {
  methods: {
    trackVirtualPageView: function(name) {
      if (auth.getShadowLogin()) {
        return;
      }

      try {
        this.$ga.query("send", {
          hitType: "pageview",
          page: "/virtual-page-view/" + name
        });
      } catch (e) {
        console.error("GAVPV", e);
      }

      try {
        // probably #ISSUE
        if (process.env.NODE_ENV === "production") {
          window.hj("vpv", "/vpv/" + name);
        }
      } catch (e) {
        console.error("HJ", e);
      }
    }
  }
};
