export const TransactionReportPaginated = `{
    lastPage
    currentPage
    total
    limit
    data {
      uuid
      date
      amount
      type
      status
      business {
        uuid
        display_name
      }
      campaign {
        uuid
        name
      }
    }
  }
`;