<template>
  <v-app v-if="!loading.system" id="inspire" class="primary--text">
    <v-system-bar v-if="isShadowLogin" color="error" :height="45" app>
      <div class="text-center white--text">
        <v-icon color="white" small>fal fa-exclamation-triangle</v-icon> Shadow
        login! Warning: you're acting on behalf of the user! 
      </div>
    </v-system-bar>
    <v-system-bar
      v-if="showExpiredTopBar"
       color="#FED744"
        height="50"
        app
      >
      <div class="error-message">
        <v-icon color="white" small>fal fa-exclamation-triangle</v-icon>
         Lost connection to Facebook! <router-link :to="{ name: 'channels' }">Connect your Facebook account again</router-link> 
      </div>
      </v-system-bar>
      <v-dialog v-model="isExpiredDialog" max-width="550px">
      <v-card>
        <v-btn
        icon
        
            color="primary"
            class="d-flex ml-auto"
            @click="showExpiredDialog = false"
          >
          <v-icon large color="primary" small>fal fa-times</v-icon>

          </v-btn>
        <v-card-text class="pt-3 text-center">
        <v-icon color="warning" class="pb-10" x-large>fal fa-exclamation-triangle</v-icon>  
        <div class="primary--text">Please connect your Facebook account again, otherwise your dashboard will be locked 😊 <br/>
        <router-link :to="{ name: 'channels' }" @click.native="showExpiredDialog = false">Click here to connect again</router-link> </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-system-bar
      v-if="subscriptionExpire"
      :color="
        (subscriptionExpire.type === 'day' && subscriptionExpire.value <= 10) ||
        subscriptionExpire.type === 'hour'
          ? 'error'
          : 'warning'
      "
      :height="45"
      app
    >
      <div class="w-100 h-100 d-flex align-center justify-center white--text">
        <v-icon color="white" small class="mr-2"
          >fal fa-exclamation-triangle</v-icon
        >
        <span class="mr-1">Subscription expires</span>
        <span
          v-if="subscriptionExpire.type === 'day'"
          class="font-weight-bold mr-1"
        >
          {{
            subscriptionExpire.value === 1
              ? "Tomorrow"
              : `in ${subscriptionExpire.value} days`
          }}
        </span>
        <span
          v-if="subscriptionExpire.type === 'hour'"
          class="font-weight-bold mr-1"
        >
          <span v-if="subscriptionExpire.value > 0">
            {{
              subscriptionExpire.value === 1 || subscriptionExpire.value === 21
                ? `in ${subscriptionExpire.value} hour`
                : `in ${subscriptionExpire.value} hours`
            }}
          </span>
          <span v-else>
            less than an hour
          </span>
        </span>
        <span
          v-if="subscriptionExpire.type === 'date'"
          class="font-weight-bold mr-1"
          >d.
          {{
            subscriptionExpire.value | utc_to_local_datetime("D MMMM YYYY")
          }}</span
        >
        <span
          class="cursor-pointer text-underline"
          @click="resumeSubscription()"
          >Resume</span
        >
      </div>
    </v-system-bar>

    <!--If logged in-->
    <div v-if="user">
      <the-required-actions v-if="hasRequiredActions"></the-required-actions>
      <the-subscription-dialog
        v-else-if="user.business && !hasSubscription"
      ></the-subscription-dialog>
      <the-chat-dialog v-else-if="chatVisible"></the-chat-dialog>
      <the-notification-dialog></the-notification-dialog>

      <template v-if="!hasRequiredActions">
        <the-app-menu
          v-if="!isTvDashboardPage"
          v-model="showAppMenu"
        ></the-app-menu>
        <the-app-toolbar
          v-if="!isTvDashboardPage"
          @toggle-menu="showAppMenu = !showAppMenu"
        ></the-app-toolbar>

        <v-main app>
          <!--If loading-->
          <v-container v-if="loading.page" fluid fill-height>
            <v-layout align-center justify-center>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-layout>
          </v-container>

          <!--If not loading page-->
          <v-container
            v-show="!loading.page"
            fluid
            class="p-3"
            :class="{ 'h-100': this.$router.currentRoute.name === 'chat' }"
          >
            <router-view></router-view>
          </v-container>
        </v-main>
      </template>
    </div>
    <div class=" h-100 d-flex justify-center" v-else>
      <router-view></router-view>
    </div>

    <!-- App updated -->
    <v-snackbar
      v-model="isSystemUpdated"
      color="success"
      :timeout="-1"
      class="cursor-pointer"
      :multi-line="true"
      @click.native="reloadSystem"
    >
      Platform has been updated - click to reload
    </v-snackbar>

    <v-snackbar v-model="showSnackbar" :color="snackbar.color" :timeout="3500">
      {{ snackbar.text }}
      <v-btn text @click="showSnackbar = false">
        <v-icon color="white">fal fa-times</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TheAppMenu from "@/components/app/TheAppMenu.vue";
import TheAppToolbar from "@/components/app/TheAppToolbar.vue";
import TheSubscriptionDialog from "@/components/app/TheRequiredActions/business-actions/TheSubscriptionDialog.vue";
import TheNotificationDialog from "@/components/app/notifications/TheNotificationDialog.vue";
import TheRequiredActions from "@/components/app/TheRequiredActions/TheRequiredActions.vue";
import TheChatDialog from "@/components/app/Chat/TheChatDialog.vue";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  components: {
    TheSubscriptionDialog,
    TheChatDialog,
    TheNotificationDialog,
    TheRequiredActions,
    TheAppToolbar,
    TheAppMenu
  },
  filters: { utc_to_local_datetime },
  data: () => ({
    showAppMenu: null,
    currentAssetKey: "",
    resumeLoading: false,
    showExpiredDialog: true
  }),
  computed: {
    ...mapState("loading", {
      loading: state => state
    }),
    ...mapState("snackbar", {
      snackbar: state => state
    }),
    ...mapState("settings", ["settings", "buildVersion"]),
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", [
      "businessActionRequired",
      "influencerActionRequired",
      "channelApiExpired"
    ]),
    ...mapGetters("subscriptions", ["getActiveSubscription", "hasSubscription"]),
    isExpiredDialog() {
      return this.channelApiExpired >= 7 && this.channelApiExpired <= 13 && this.showExpiredDialog
    },
    isShadowLogin() {
      return JSON.parse(this.$authHelper.getShadowLogin());
    },
    showExpiredTopBar() {
      return this.channelApiExpired && this.channelApiExpired <= 13 || this.channelApiExpired === 0
    },
    subscriptionExpire() {
      const details = this.getActiveSubscription;
      if (details && details.ends_at) {
        const today = Date.parse(new Date());
        const end = Date.parse(details.ends_at);
        const difference = end - today;
        const day = 1000 * 60 * 60 * 24;
        const hour = 1000 * 60 * 60;
        const daysRemain = Math.floor(difference / day);
        if (daysRemain > 30) {
          return { value: details.ends_at, type: "date" }; // return date result
        }
        if (daysRemain !== 0) {
          return { value: Math.floor(difference / day), type: "day" }; // return days result
        } else {
          return { value: Math.floor(difference / hour), type: "hour" }; // return hours result
        }
      } else {
        return false;
      }
    },

    showSnackbar: {
      get() {
        return this.snackbar.show;
      },
      set(val) {
        this.showSnackBar(val);
      }
    },
    hasRequiredActions() {
      if (this.user.influencer && this.influencerActionRequired) {
        return true;
      }
      if (this.user.business && this.businessActionRequired) {
        return true;
      }
      return false;
    },
    chatVisible() {
      if (this.user.moderator) {
        return false;
      }

      return true;
    },
    isSystemUpdated() {
      return this.currentAssetKey && this.currentAssetKey !== this.buildVersion;
    },
    isTvDashboardPage() {
      return this.$route.name === "tv_dashboard";
    }
  },
  watch: {
    buildVersion(newValue) {
      if (newValue && !this.currentAssetKey) {
        this.currentAssetKey = newValue;
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapActions("core", ["updateSelfSubscription"]),
    ...mapActions("subscriptions", ["setSubscription"]),

    reloadSystem() {
      location.reload();
    },
    resumeSubscription() {
      this.resumeLoading = true;
      const params = {
        resume: true,
        product: "standard"
      };
      this.updateSelfSubscription(params).then(
        updateSelfSubscription => {
          this.resumeLoading = false;
          this.setSnackSuccess("Subscription has been resumed");
          const selfSubscription = {
            ...updateSelfSubscription.data[0],
            ends_at: null
          };
          this.setSubscription([selfSubscription]);
        },
        () => {
          this.resumeLoading = false;
          this.setSnackError("Something went wrong.");
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.text-underline {
  text-decoration: underline;
}
.error-message {
  text-align: center;
  color: #fff;
  width: 100%;
  font-size: 16px;
}
</style>
