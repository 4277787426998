/* eslint-disable no-unused-vars */
import SignUp from "@/components/app/SignUp";
import TheLogin from "@/components/app/TheLogin";
import BusinessSignUp from "@/components/forms/BusinessSignUp";
import InfluenserSignUp from "@/components/forms/InfluenserSignUp";
import { authHelper } from "@/helpers/classes/auth";
import posthog from "posthog-js";
import Vue from "vue";
import VueRouter from "vue-router";
import { getUserTypeRedirectUrl } from "../helpers/get-user-type-redirect-url";

const auth = authHelper();

const routes = [
  {
    path: "/registration",
    component: SignUp,
    children: [
      {
        path: "/business-signup",
        component: BusinessSignUp,
      },
      {
        path: "/influencer-signup",
        component: InfluenserSignUp,
      },
    ],
  },
  {
    path: "/login",
    component: TheLogin,
  },
];
const router = new VueRouter({
  mode: "history", // History removes permanent # from URL
  linkActiveClass: "active",
  exact: true,

  // Only works if browser supports "pushState"
  scrollBehavior(to, from) {
    // Only scroll if path is different
    if (to && from && to.path !== from.path) {
      return new Promise((resolve) => {
        resolve({ x: 0, y: 0 });
      });
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // change page title
  // if (to.meta && to.meta.title) {
  //   console.log(document.title)
  //   document.title = to.meta.title;
  // }
  // validate token if not first running
  if (from.name) {
    router.app.$store.dispatch("core/auth/checkAuth").then(
      (response) => {
        if (response) {
          // if toke valid continue
          next();
        } else {
          // check if token expired
          auth.checkToken().then(
            (response) => {
              // token not expired yet or successfully refreshed
              next();
            },
            (error) => {
              // token expired and was not refreshed
              router.push("/login");
              location.reload();
              router.app.$store.dispatch("core/auth/logout");
            }
          );
        }
      },
      (error) => {
        // failed to validate auth
        router.push("/login");
        location.reload();
        router.app.$store.dispatch("core/auth/logout");
      }
    );
  } else {
    // only for first load app
    next();
  }
});

router.beforeEach(async (_to, _from, next) => {
  console.log("shadow login", auth.getShadowLogin());

  if (auth.getShadowLogin()) return next();

  console.log("user type", auth.getUserType());

  const redirectUrl = getUserTypeRedirectUrl(auth.getUserType()?.toLowerCase());

  console.log("redirect url", redirectUrl);

  if (redirectUrl) {
    console.log("logging out and redirecting to", redirectUrl.toString());
    auth.destroyTokens();

    window.location.href = redirectUrl.toString();

    return;
  }

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    posthog.capture("$pageview", {
      $current_url: to.fullPath,
    });
  });
});

export default router;
