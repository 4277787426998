export const TransactionsPaginated = `{
    lastPage
    currentPage
    total
    limit
    data {
      uuid
      date
      amount
      tax_amount
      total_amount
      status
      business {
        uuid
        display_name
      }
    }
}
`;

export const TransactionReportPaginated = `{
  lastPage
  currentPage
  total
  limit
  data {
    uuid
    date
    amount
    type
    status
    refund
    refund_reason
    refund_reason_text
    internal_note
    business {
      uuid
      display_name
    }
    influencer {
      uuid
      user {
        full_name
      }
    }
    invoice {
      uuid
      number
    }
    approves_at
    invoices_at
  }
}
`;

export const TransactionSchema = `{
    uuid
    date
    amount
    type
    status
    refund
    refund_reason
    refund_reason_text
    internal_note
    business {
      uuid
      display_name
    }
    influencer {
      uuid
      user {
        full_name
      }
    }
    invoice {
      uuid
      number
    }
    approves_at
    invoices_at
}`;