/* eslint-disable no-unused-vars */
import { AgencySchema } from "@/helpers/schemas/core/users.js";

const actions = {
  createAgency({ dispatch }, params) {
    const data = {
      params: params,
      result: AgencySchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createAgency", data).then(
          response => {
            resolve(response.data.createAgency);
          },
          error => {
            reject(error);
          }
      );
    });
  }
};

export default actions;
