import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";

import auth from "./modules/auth/store.js";
import notifications from "./modules/notifications/store.js";
import chats from "./modules/chats/store.js";
import campaigns from "./modules/campaigns/store.js";

const modules = {
  auth,
  notifications,
  chats,
  campaigns
};

const module = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: modules
};

export default module;
