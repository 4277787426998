import * as type from "./mutation-types";
import moment from "moment";
// Change state data
const mutations = {
  [type.SET_UNREAD_NOTIFICATIONS](state, number) {
    state.unread = number;
  },
  [type.SET_READ_NOTIFICATIONS](state, payload) {
    state.notifications = payload;
  },
  [type.ADD_READ_NOTIFICATION](state, payload) {
    state.notifications.push(payload);
  },
  [type.READ_NOTIFICATION](state, notification) {
    let index = state.notifications
      .map(item => item.id)
      .indexOf(notification.id);
    // If index doesn't exist add the notification
    if (index > -1) {
      state.notifications[index].read_at = moment()
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      state.unread--;
    }
  },
  toggleNotificationsDialog(state, show) {
    if (state.showNotificationsDialog !== show) {
      state.showNotificationsDialog = show;
    } else {
      state.showNotificationsDialog = !show;
    }
  }
};

export default mutations;
