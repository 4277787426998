// Change state data
import * as type from "./mutation-types";

const mutations = {
  [type.SET_SNACK](state, payload) {
    state.show = true;
    state.text = payload.text;
    state.color = payload.color;
  },
  [type.SHOW_SNACK](state, show) {
    state.show = show;
  }
};

export default mutations;
