/* eslint-disable no-unused-vars */

import {
  LanguagesSchema,
  TranslationAreasSchema,
  TranslationKeysSchema,
  TranslationsSchema,
  TranslationSchema,
  EditTranslationsSchema
} from "@/helpers/schemas/core/translations.js";
const actions = {
  getLanguages({ dispatch }, params) {
    const data = {
      params: params,
      result: LanguagesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("languages", data).then(
        response => {
          resolve(response.data.languages);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createLanguage({ dispatch }, params) {
    const data = {
      params,
      result: LanguagesSchema,
      type: "Language"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createLanguage", data).then(
        response => {
          resolve(response.data.createLanguage);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateLanguage({ dispatch }, params) {
    const data = {
      params,
      result: LanguagesSchema,
      type: "Language"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateLanguage", data).then(
        response => {
          resolve(response.data.updateLanguage);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  getTranslationAreas({ dispatch }, params) {
    const data = {
      params: params,
      result: TranslationAreasSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("translationAreas", data).then(
        response => {
          resolve(response.data.translationAreas);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getTranslationArea({ dispatch }, params) {
    const data = {
      params: params,
      result: TranslationAreasSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("translationArea", data).then(
        response => {
          resolve(response.data.translationArea);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createTranslationArea({ dispatch }, params) {
    const data = {
      params,
      result: TranslationAreasSchema,
      type: "TranslationArea"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createTranslationArea", data).then(
        response => {
          resolve(response.data.createTranslationArea);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createTranslationKey({ dispatch }, params) {
    const data = {
      params,
      result: TranslationKeysSchema,
      type: "TranslationKey"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createTranslationKey", data).then(
        response => {
          resolve(response.data.createTranslationKey);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateTranslation({ dispatch }, params) {
    const data = {
      params,
      result: EditTranslationsSchema,
      type: "Translation"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("upsertTranslation", data).then(
        response => {
          resolve(response.data.upsertTranslation);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removeTranslationAreas({ dispatch }, params) {
    const data = {
      params,
      result: TranslationKeysSchema,
      type: "TranslationKey"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql
        .mutate("removeTranslationAreasForTranslationKey", data)
        .then(
          response => {
            resolve(response.data.removeTranslationAreasForTranslationKey);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  addTranslationAreas({ dispatch }, params) {
    const data = {
      params,
      result: TranslationKeysSchema,
      type: "TranslationKey"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("addTranslationAreasToTranslationKey", data).then(
        response => {
          resolve(response.data.addTranslationAreasToTranslationKey);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  getTranslations({ dispatch }, params) {
    const data = {
      params: params,
      result: TranslationsSchema,
      type: "TranslationsListPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("translations", data).then(
        response => {
          resolve(response.data.translations);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getTranslation({ dispatch }, params) {
    const data = {
      params: params,
      result: TranslationSchema,
      type: "Translation"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("translation", data).then(
        response => {
          resolve(response.data.translation);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default actions;
