/* eslint-disable no-unused-vars */

import {
  CampaignSchema,
  CampaignsSchema,
  CreateCampaignSchema,
  CampaignInfluencerSchema,
  CampaignInfluencersSchema,
  CampaignInfluencersGroupSchema,
  CreateCampaignInfluencerSchema,
  getCampaignsForInfluencerSchema,
  CampaignsSummarySchema,
  BulkInviteCampaignSchema,
  RetractCampaignRequestSchema,
  CampaignRequestsPaginatedSchema,
  CampaignRequestCountsSchema,
  InfluencerRequestsPaginatedSchema,
  InfluencerRequestsCountsSchema,
  ModeratorInfluencerRequestsPaginatedSchema,
} from "@/helpers/schemas/core/campaigns.js";

const actions = {
  loadCampaigns({ dispatch }, params) {
    const data = {
      params: params,
      result: CampaignsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaigns", data).then(
        response => {
          resolve(response.data.campaigns);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  loadCampaignsSummary({ dispatch }, params) {
    const data = {
      params: params,
      result: CampaignsSummarySchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaigns", data).then(
        response => {
          resolve(response.data.campaigns);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  loadCampaign({ dispatch }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaign", data).then(
        response => {
          resolve(response.data.campaign);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  bulkInviteCampaign({ dispatch }, params) {
    const data = {
      params: params,
      result: BulkInviteCampaignSchema,
      type: "BulkCampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("bulkCreateCampaignInfluencer", data).then(
        response => {
          resolve(response.data.bulkCreateCampaignInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignInfluencers({ dispatch }, params) {
    const data = {
      params: params,
      result: CampaignInfluencersSchema,
      type: "CampaignInfluencersPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignInfluencers", data).then(
        response => {
          resolve(response.data.campaignInfluencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignInfluencersGroups({ commit }, params) {
    const data = {
      params: params,
      result: CampaignInfluencersGroupSchema,
      type: "CampaignInfluencersGrouped"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignInfluencersGroupedByStatus", data).then(
        response => {
          resolve(response.data.campaignInfluencersGroupedByStatus);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CreateCampaignSchema,
      type: "CampaignDraft"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createCampaign", data).then(
        response => {
          resolve(response.data.createCampaign);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateCampaign", data).then(
        response => {
          resolve(response.data.updateCampaign);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  endCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "EndCampaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("endCampaign", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removeCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteCampaign", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  copyCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("copyCampaign", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCampaignImage({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.uploadCampaignImage("updateCampaignImage", data).then(
        response => {
          resolve(response.data.updateCampaignImage);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  publishCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CampaignSchema,
      type: "Campaign"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("publishCampaign", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  inviteInfluencerToCampaign({ commit }, params) {
    const data = {
      params: params,
      result: CreateCampaignInfluencerSchema,
      type: "CampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createCampaignInfluencer", data).then(
        response => {
          resolve(response.data.createCampaignInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateCampaignInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: CampaignInfluencerSchema,
      type: "CampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateCampaignInfluencer", data).then(
        response => {
          resolve(response.data.updateCampaignInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteCampaignInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: CampaignInfluencerSchema,
      type: "CampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteCampaignInfluencer", data).then(
        response => {
          resolve(response.data.deleteCampaignInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  joinCampaignByInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: CampaignInfluencerSchema,
      type: "CampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createCampaignInfluencerByInfluencer", data).then(
        response => {
          resolve(response.data.createCampaignInfluencerByInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  joinCampaignInByInfluencerOnInvite({ commit }, params) {
    const data = {
      params: params,
      result: CampaignInfluencerSchema,
      type: "CampaignInfluencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateCampaignInfluencerByInfluencer", data).then(
        response => {
          resolve(response.data.updateCampaignInfluencerByInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignsForInfluencer({ dispatch }, params) {
    const data = {
      params: params,
      result: getCampaignsForInfluencerSchema,
      type: "CampaignInfluencersPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignInfluencersForInfluencers", data).then(
        response => {
          resolve(response.data.campaignInfluencersForInfluencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  retractCampaignRequest({ commit }, params) {
    const data = {
      params,
      result: RetractCampaignRequestSchema,
      type: "CampaignRequest"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("retractCampaignRequest", data, true).then(
        response => {
          resolve(response.data.retractCampaignRequest);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignRequestsForInfluencer({ dispatch }, params) {
    const data = {
      params,
      result: CampaignRequestsPaginatedSchema,
      type: "CampaignRequestsPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignRequestsForInfluencers", data).then(
        response => {
          resolve(response.data.campaignRequestsForInfluencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignRequestCountsForInfluencer({ dispatch }) {
    const data = {
      params: { limit: 0 },
      result: CampaignRequestCountsSchema,
      type: "CampaignRequestsPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignRequestsForInfluencers", data).then(
        response => {
          resolve(response.data.campaignRequestsForInfluencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignRequestsForBusiness({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerRequestsPaginatedSchema,
      type: "BusinessInfluencerRequestsPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignRequestsForBusinesses", data).then(
        response => {
          resolve(response.data.campaignRequestsForBusinesses);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignRequestCountsForBusiness({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerRequestsCountsSchema,
      type: "BusinessInfluencerRequestsPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignRequestsForBusinesses", data).then(
        response => {
          resolve(response.data.campaignRequestsForBusinesses);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCampaignRequestsForModerator({ dispatch }, params) {
    const data = {
      params,
      result: ModeratorInfluencerRequestsPaginatedSchema,
      type: "ModeratorInfluencerRequestsPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignRequestsForModerators", data).then(
        response => {
          resolve(response.data.campaignRequestsForModerators);
        },
        error => {
          reject(error);
        }
      );
    });
  },
};

export default actions;
