/* eslint-disable no-unused-vars */

import { PromotionCodeSchema } from "@/helpers/schemas/business/promotionCodes.js";
import { FavoriteInfluencerSchema } from "@/helpers/schemas/business/influencers.js";
import {
  BusinessStatsSchema,
  UpdateBusinessSchema,
  ManagedOfferSchema
} from "@/helpers/schemas/business/business.js";

import {
  InfluencerChannelPostsSchema,
  AttachInfluencerChannelPostsSchema,
  DetachInfluencerChannelPostsSchema,
} from "@/helpers/schemas/business/influencerChannelPosts"

import {
  TransactionSchema,
  TransactionReportPaginated
} from "../../../helpers/schemas/business/transactions.js";

const actions = {
  updateBusiness({ dispatch }, settings) {
    const data = {
      params: settings,
      result: UpdateBusinessSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateBusiness", data).then(
        response => {
          resolve(response.data.updateBusiness);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getPromotionCodes({ dispatch }, params) {
    const data = {
      params: params,
      result: PromotionCodeSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getInfluencerPromotionCodes", data).then(
        response => {
          resolve(response.data.getInfluencerPromotionCodes);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addPromotionCode({ dispatch }, promotionCode) {
    const data = {
      params: promotionCode,
      result: PromotionCodeSchema,
      type: "InfluencerPromotionCode"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createInfluencerPromotionCode", data).then(
        response => {
          resolve(response.data.createInfluencerPromotionCode);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setFaviriteInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: FavoriteInfluencerSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("attachFavoriteInfluencer", data).then(
        response => {
          resolve(response.data.attachFavoriteInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  unsetFaviriteInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: FavoriteInfluencerSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("detachFavoriteInfluencer", data).then(
        response => {
          resolve(response.data.detachFavoriteInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  removePromotionCode({ dispatch }, params) {
    const data = {
      params: params,
      result: PromotionCodeSchema,
      type: "InfluencerPromotionCode"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteInfluencerPromotionCode", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getBusinessStats({ commit }, params) {
    const data = {
      params: params,
      result: BusinessStatsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("businessStats", data).then(
        response => {
          resolve(response.data.businessStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // transactions report
  getTransactionsReport({ commit }, params) {
    const data = {
      params: params,
      result: TransactionReportPaginated
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("transactions", data).then(
          response => {
            resolve(response.data.transactions);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  createUpfrontTransaction({ commit }, params) {
    const data = {
      params,
      result: TransactionSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createUpfrontTransaction", data, true).then(
        response => {
          resolve(response.data.createUpfrontTransaction);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerChannelPosts({ commit }, params) {
    const data = {
      params,
      result: InfluencerChannelPostsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelPosts", data).then(
        response => {
          resolve(response.data.influencerChannelPosts);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerChannelPostsAttachable({ commit }, params) {
    const data = {
      params,
      result: InfluencerChannelPostsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelPostsAttachable", data).then(
        response => {
          resolve(response.data.influencerChannelPostsAttachable);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  attachInfluencerChannelPosts({ commit }, params) {
    const data = {
      params,
      result: AttachInfluencerChannelPostsSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("attachInfluencerChannelPosts", data, true).then(
        response => {
          resolve(response.data.attachInfluencerChannelPosts);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  detachInfluencerChannelPosts({ commit }, params) {
    const data = {
      params,
      result: DetachInfluencerChannelPostsSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("detachInfluencerChannelPosts", data, true).then(
        response => {
          resolve(response.data.detachInfluencerChannelPosts);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default actions;
