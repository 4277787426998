// Methods. NEVER update state data from an action/method
// Update state data with mutations
import * as type from "./mutation-types";
import moment from "moment";
import { NotificationSchema } from "@/helpers/schemas/core/notifications.js";
import { convertString } from "@/helpers/filters/convertors.js";

const actions = {
  updateUnreadNotifications({ commit, state, dispatch }, list) {
    let first = state.notifications[0];

    for (let i = 0; i < list.length; i++) {
      let item = list[i];

      // Only add newest unread to list
      if (!first || moment(item.created_at).isAfter(first.created_at)) {
        dispatch("addNotification", item);
      }
    }

    commit(type.SET_UNREAD_NOTIFICATIONS, list.length);
  },
  addNotification({ commit, state }, notification) {
    // find index of your object
    let exists = state.notifications.filter(
      item => item.id === notification.id
    );

    // If doesn't exist add the notification
    if (exists.length === 0) {
      commit(type.ADD_READ_NOTIFICATION, notification);
    }
  },
  readNotification({ commit }, notification) {
    commit(type.READ_NOTIFICATION, notification);
  },
  clearNotifications({ commit }) {
    commit(type.SET_READ_NOTIFICATIONS, []);
  },
  getNotifications({ dispatch }, params) {
    const data = {
      params: params,
      result: NotificationSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("notifications", data).then(
        response => {
          for (let i = 0; i < response.data.notifications.length; i++) {
            let notification = response.data.notifications[i];
            if (notification.data && typeof notification.data === "string") {
              notification.data = convertString(notification.data);
            }
            dispatch("addNotification", notification);
          }
          resolve(response.data.notifications);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getNotification({ dispatch }, id) {
    const data = {
      params: { id },
      result: NotificationSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("notification", data).then(
        response => {
          let notification = response.data.notification;
          if (notification.data && typeof notification.data === "string") {
            notification.data = convertString(notification.data);
          }
          dispatch("readNotification", notification);
          resolve(notification);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default actions;
