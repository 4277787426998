import * as type from "./mutation-types";

const actions = {
    setPlaying({ commit }, playing) {
        commit(type.SET_PLAYING, playing);
    },
    setMuted({ commit }, muted) {
        commit(type.SET_MUTED, muted);
    }
};

export default actions;