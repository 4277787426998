export default {
  methods: {
    getNotificationMeta(notification) {
      let meta = {
        color: "primary",
        icon: "",
        title: "",
        link: ""
      };

      let all = require("./notifications/all.js");
      meta = all.getNotificationMeta(this, meta, notification);

      return meta;
    }
  }
};
