<template>
  <v-avatar :color="backgroundColor" :size="size">
    <!-- If has avatar -->
    <template v-if="!imageNotFound && user.avatar_url">
      <img :src="user.avatar_url" @error="imageNotFound = true"/>
    </template>
    <!--If no avatar-->
    <span v-else class="font-weight-bold" :class="textColor">
      {{ user.first_name | first_letter }}
    </span>
  </v-avatar>
</template>

<script>
import first_letter from "@/helpers/filters/first_letter";

export default {
  filters: {
    first_letter
  },
  props: {
    user: {
      type: Object
    },
    size: {
      required: false,
      type: Number,
      default: 32
    },
    backgroundColor: {
      default: "primary"
    },
    textColor: {
      default: "white--text"
    }
  },
  data: () => ({
    imageNotFound: false // If error force avatar letter
  })
};
</script>
