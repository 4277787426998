<template>
  <v-card>
    <router-link
      :to="{ name: 'channel', params: { id: influencerChannel.uuid } }"
      tag="div"
      class="p-3 text-center cursor-pointer"
    >
      <v-avatar class="elevation-1" :size="150">
        <img :src="influencerChannel.profile_image_url" />
      </v-avatar>
    </router-link>
    <v-card-text class="pa-0">
      <v-list two-line class="pa-0">
        <v-list-item>
          <v-list-item-action>
            <v-icon>{{ influencerChannel.channel.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a :href="influencerChannel.profile_url" target="_blank">
                {{ influencerChannel.username }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>{{
              influencerChannel.channel.display_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-icon>fal fa-users</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ influencerChannel.followers | local_numbers }}
            </v-list-item-title>
            <v-list-item-subtitle>Followers</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-icon>fal fa-camera-alt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ influencerChannel.media_count | local_numbers }}
            </v-list-item-title>
            <v-list-item-subtitle>Posts</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-icon>fal fa-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ influencerChannel.api_updated_at | utc_to_local_datetime }}
            </v-list-item-title>
            <v-list-item-subtitle>Data updated</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions class="d-block">
      <v-btn color="primary" outlined block @click="showSettingsDialog = true">
        <v-icon class="mr-2">fal fa-cog</v-icon>
        Choose categories
      </v-btn>
      <div>
        <v-btn
        v-if="isApiExpired"
          color="#4267B2"
          class="white--text mt-5"
          large
          block
          :href="
            influencerChannel.channel.login_link +
              '?user_uuid=' +
              user.uuid +
              '&redirectUrl=' +
              windowLink
          "
          :loading="loading"
          @click="loading = true"
        >
          <v-icon left>fab fa-facebook</v-icon> Connect with Facebook
        </v-btn>
      </div>

      <v-dialog v-model="showSettingsDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <div>
              <div class="headline">Settings</div>
              <div class="subtitle-1 light-grey">
                Choose the categories that best describe the content on
                {{ influencerChannel.username }}
              </div>
            </div>
          </v-card-title>

          <v-card-text>
            <v-list two-line class="pa-0">
              <v-list-item
                v-for="category in categories"
                :key="category.id"
                @click="toggleCategory(category)"
              >
                <v-list-item-action>
                  <v-icon>{{ category.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ category.display_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon v-if="hasCategory(category)" color="success" small
                      >fal fa-check</v-icon
                    >
                    <v-icon v-else color="error" small>fal fa-times</v-icon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="selectedCategories.length === 3"
              color="primary"
              @click="save()"
            >
              Save
            </v-btn>
            <v-btn v-else disabled>
              Choose 3 categories
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  filters: { utc_to_local_datetime, local_numbers },
  props: {
    influencerChannel: {
      required: true,
    },
  },
  data: () => ({
    showSettingsDialog: false,
    categories: [],
    selectedCategories: [],
    loading: false,
  }),
  computed: {
    ...mapState("core/auth", ["user"]),

    ...mapGetters("core/auth", [ "channelApiExpired" ]),
    isApiExpired () {
     return this.channelApiExpired && this.channelApiExpired <= 13 || this.channelApiExpired === 0
    },
    windowLink() {
      return window.location.href;
    },
  },
  methods: {
    ...mapActions("influencer", [
      "getChannelCategories",
      "updateInfluencerChannel",
    ]),

    hasCategory(category) {
      // Filter array to check if the category is selected
      let categoryArr = this.selectedCategories.filter(
        (item) => item.id === category.id
      );

      // Returns true if the category is selected
      return categoryArr.length !== 0;
    },
    toggleCategory(category) {
      // If the category is selected remove it. Else add
      if (this.hasCategory(category)) {
        // Just override array by filtering current selected array to not include the category
        this.selectedCategories = this.selectedCategories.filter(
          (item) => item.id !== category.id
        );
      } else {
        // Cap to max 3 categories
        if (this.selectedCategories && this.selectedCategories.length >= 3) {
          this.setSnackError("You can only choose 3");
          return;
        }

        this.selectedCategories.push(category);
      }
    },
    save() {
      let influencerChannel = {
        uuid: this.influencerChannel.uuid,
        categories: this.selectedCategories.map((category) =>
          Number(category.id)
        ),
        active: 1,
      };

      // Add active on "local" prop for saving data
      this.updateInfluencerChannel(influencerChannel)
        .then((data) => {
          this.showSettingsDialog = false;
          this.$emit("input", data);
          this.setSnackSuccess("Saved");
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
        });
    },
  },
  created() {
    // Break reference (Causes error when coming from store)
    this.selectedCategories = this.cloneDeep(this.influencerChannel.categories);
    this.getChannelCategories().then((data) => {
      this.categories = data;
    });
  },
};
</script>
