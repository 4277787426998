export const ChannelCategoriesSchema = `{
    id
    name
    display_name
    description
    icon
    created_at
    updated_at
}`;

export const InfluencerChannelCitiesSchema = `{
    paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
    }
    data {
        id
        name
        created_at
        updated_at
    }
}`;

export const InfluencerChannelCitySchema = `{
    id
    name
    created_at
    updated_at
}`;

export const InfluencerChannelAgeGendersSchema = `{
    id
    age
    gender
    created_at
    updated_at
}`;
