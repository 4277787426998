<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step step="1" :complete="!missingChannels">
      Connect media
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container fluid class="pl-0 pr-0">
        <v-layout wrap>
          <v-flex v-for="channel in channels" :key="channel.id" lg4 md6 xs12>
            <div class="cursor-pointer" @click="channelClick(channel)">
              <v-card class="elevation-0 bordered">
                <div class="p-1" :class="channel.name | channel_color">
                  <v-container fluid fill-height>
                    <v-layout align-center>
                      <v-icon color="white" :size="85">
                        {{ channel.icon }}
                      </v-icon>

                      <div>
                        <div class="display-1 white--text ml-3">Instagram</div>
                        <div class="caption white--text ml-3">
                          Has to be connected with Facebook
                        </div>
                      </div>
                    </v-layout>
                  </v-container>
                </div>
                <v-card-text>
                  <v-btn
                    color="#4267B2"
                    class="white--text"
                    large
                    block
                    :loading="loading"
                  >
                    <v-icon left>fab fa-facebook</v-icon> Connect with Facebook
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>

    <v-stepper-step step="2" :rules="[() => step !== 2]" :complete="step > 2"
      >Status</v-stepper-step
    >

    <v-stepper-content step="2">
      <p>
        We could not connect your Instagram profile to the platform.
      </p>

      <div class="font-weight-bold">
        How do I solve the problem?
      </div>

      <ul>
        <li>Instagram profile has to be a business profile</li>
        <li>Profilen has to be connected to a Facebook page</li>
        <li>You need admin rights to the Facebook page</li>
      </ul>
      <br />

      <p>
        Read our guide:
        <a href="https://hubs.ly/H0vBqKC0" target="_blank">
          How do I sign up as an influencer at Make Influence</a
        ><br />
      </p>
      <p>
        If you continue to experience issues, reach out to
        support@makeinfluence.com
      </p>

      <div class="mt-3 mb-4">
        <v-btn
          color="success"
          large
          @click="
            step = 1;
            facebookError = false;
          "
        >
          Try again
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step step="3" :complete="!channelsMissingCategories">
      Categories
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-container fluid grid-list-lg class="pl-0 pr-0">
        <v-alert type="warning" :value="!anyChannelMeetsRequirement">
          Unfortunately, none of your channels meet the minimum requirement of
          1500 followers, but we recommend that you take a look at the following guide:
          <a
            href="https://makeinfluence.com/dk/influencer/blog/guides/faa-flere-foelgere"
            target="_blank"
            >How do I get more followers?</a
          >
        </v-alert>

        <v-layout wrap>
          <v-flex
            v-for="influencerChannel in filteredInfluencerChannels"
            :key="influencerChannel.id"
            lg4
            md6
            xs12
          >
            <connect-influencer-channel-card
              :influencer-channel="influencerChannel"
              bordered
            ></connect-influencer-channel-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-btn
        class="ml-3 mb-4"
        color="success"
        large
        :loading="loading"
        @click="reloadPage()"
      >
        Continue
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import channel_color from "@/helpers/filters/channel_color";
import ConnectInfluencerChannelCard from "../../../cards/ConnectInfluencerChannelCard";

export default {
  components: { ConnectInfluencerChannelCard },
  filters: { channel_color },
  data: () => ({
    step: 1,
    channels: [],
    loading: false,
    influencerChannels: []
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", [
      "missingChannels",
      "channelsMissingCategories"
    ]),
    filteredInfluencerChannels() {
      // Display non active profiles OR active that hasn't been approved or where api failed
      return this.influencerChannels.filter(
        item =>
          !item.active ||
          (item.active && (!item.approved_at || item.api_failed_at))
      );
    },
    anyChannelMeetsRequirement() {
      return (
        this.filteredInfluencerChannels.filter(item => item.followers >= 1000)
          .length > 0
      );
    },
    windowLink() {
      return window.location.href;
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("influencer", ["getInfluencerChannels", "getChannels"]),
    channelClick(channel) {
      // this.trackVirtualPageView("channel-connect/login");
      // this.trackVirtualPageView("channel-connect/login/" + channel.name);
      this.loading = true;
      window.location.href =
        channel.login_link +
        "?user_uuid=" +
        this.user.uuid +
        "&redirectUrl=" +
        this.windowLink;
    },
    getAllChannels() {
      this.getChannels().then(data => {
        this.channels = data;
      });
    },
    getAllInfluencerChannels() {
      this.getInfluencerChannels({
        influencer_uuid: this.user.influencer.uuid
      }).then(data => {
        this.influencerChannels = data;
        if (data.length === 0) {
          this.step = 2;
        } else {
          this.step = 3;
        }
      });
    },
    reloadPage() {
      window.location.replace("/");
    }
  },
  created() {
    let params = this.$route.query;

    // Callback from server
    if (params.success !== undefined) {
      // If success show step 3
      if (params.success === "true") {
        this.getAllInfluencerChannels();
      } else if (params.success === "false") {
        // If error show step 2 for error page..
        this.step = 2;
      }
    }
    // this.getAllInfluencerChannels();
    this.getAllChannels();
  }
};
</script>

<style scoped></style>
