export const UpdateBusinessSchema = `{
    uuid 
    company 
    display_name 
    vat 
    phone 
    website 
    description 
    service_fee
    managed_contract_url
    webshop_system
    channel_category{
        id
    }
    approval_days
    tracking_level
    allowed_active_influencers
    allowed_active_campaigns
  }`;
export const BusinessStatsSchema = `{
    date
    clicks
    unique_clicks
    sales
    value
    commission
    conversion
    influencer{
        uuid
        user{
            first_name
            last_name
        }
    }
}`;
export const ManagedOfferSchema = `{
    status
}`;
