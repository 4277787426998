import gql from "graphql-tag";
import { ErrorSchema } from "@/helpers/schemas/core/system.js";
import { arrayType } from "../functions/defineType.js";
import StringBuilder from "./StringBuilder.js";

export default class GraphQL {
  apollo = null;
  stringBuilder = new StringBuilder();

  constructor(apollo) {
    this.apollo = apollo;
  }
  /*
    Handler for graphQL mutations.
    Used as bridgle between apollo and actions.
  */
  mutate(mutation, data, rawInput = false) {
    let includeParams = data.params
      ? (rawInput ? `(${this.paramsToString(data.params)})` : `(input: {${this.paramsToString(data.params)}})`)
      : "";
    const request = this.apollo.mutate({
      // Query
      mutation: gql`
        mutation {
          ${mutation} ${includeParams}
          ${this.resultToString(data.result, data.type)}
        }
      `
    });
    return new Promise((resolve, reject) => {
      request.then(
        response => {
          if (response.data[mutation].error_data) {
            reject(response.data[mutation]);
          }
          resolve(response);
        },
        error => {
          reject(this.handleErrors(error, "Mutation", mutation));
        }
      );
    });
  }
  /*
    Handler for graphQL query.
    Used as bridgle between apollo and actions.
  */
  query(query, data) {
    let includeParams = data.params
      ? `(${this.paramsToString(data.params)})`
      : "";
    const request = this.apollo.query({
      // Query
      query: gql`
        query {
          ${query} ${includeParams}
          ${this.resultToString(data.result, data.type)}
        }
      `,
      fetchPolicy: "no-cache"
    });
    return new Promise((resolve, reject) => {
      request.then(
        response => {
          if (response.data[query].error_data) {
            reject(response.data[query]);
          }
          resolve(response);
        },
        error => {
          reject(this.handleErrors(error, "Query", query));
        }
      );
    });
  }
  /*
    Parse every possible param in query/mutation to string.
    Used as helper for next methods: "query", "mutate".
  */
  paramsToString(value) {
    return Object.keys(value)
      .map(key => {
        return `${key}: ${this.stringBuilder.stringify(value[key])}`;
      })
      .join(",");
  }
  /*
    Parse every possible result in query/mutation to string.
    Used as helper for next methods: "query", "mutate".
  */
  resultToString(value, type) {
    if (value && type) {
      if (arrayType(type)) {
        const typeString = type
          .map(item => {
            return `
              ... on ${item} ${value[item]}
            `;
          })
          .join("\n")
          .concat(`... on Error ${ErrorSchema}`);
        return `{${typeString}}`;
      } else {
        return `{
          ... on ${type} ${value} \n 
          ... on Error ${ErrorSchema}
        }`;
      }
    } else if (value) {
      return value;
    } else {
      return "";
    }
  }
  /*
    Handle all query/mutation errors, merge them to all format.
    Used as helper for next methods: "query", "mutate".
  */
  handleErrors(error, type, query) {
    // log request type and name before errors
    console.error(`${type} failed: ${query}`);
    const errors = [];
    // first level of parsing errors
    for (let item of error.graphQLErrors) {
      errors.push(this.parseError(item));
    }
    // ========= Log errors ============
    for (let index in errors) {
      const err = errors[index];
      console.error(`
    Error #${Number(index) + 1}:
      category: ${err.category}
      field: ${err.errors[0].field}
      message: ${err.errors[0].message}
    `);
    }
    // =================================
    return errors;
  }
  /*
    Handle all sub-errors and parse them into one format.
    Used as helper for next methods: "handleErrors".
  */
  parseError(error) {
    // define global format
    const item = {
      category: error.category,
      errors: []
    };
    // second level of parsing sub-errors.
    for (let err of error.data) {
      item.errors.push({
        field: err.field,
        message: err.errors[0]
      });
    }
    return item;
  }
  /*
  Temp fix for images uploading requests.
  Used as bridgle between apollo and actions.
*/
  uploadCampaignImage(mutation, data) {
    return this.apollo.mutate({
      // Query
      mutation: gql`
        mutation(
          $image: Upload!,
          $uuid: String!
        ) {
          ${mutation}(input: {
            image: $image, uuid: $uuid
          }) ${this.resultToString(data.result, data.type)}
        }
      `,
      variables: {
        image: data.params.image,
        uuid: data.params.uuid
      },
      context: {
        useMultipart: true
      }
    });
  }
  /*
  Temp fix for images uploading requests.
  Used as bridgle between apollo and actions.
*/
  uploadAvatar(mutation, data) {
    return this.apollo.mutate({
      // Query
      mutation: gql`
        mutation(
          $image: Upload!
        ) {
          ${mutation}(input: {
            image: $image
          }) ${this.resultToString(data.result, data.type)}
        }
      `,
      variables: {
        image: data.params.image,
        uuid: data.params.uuid
      },
      context: {
        useMultipart: true
      }
    });
  }
}

