import * as type from "./mutation-types";

// Change state data
const mutations = {
  [type.SET_SETTINGS](state, payload) {
    state.settings = payload;
  },
  setBuildVersion(state, buildVersion) {
    state.buildVersion = buildVersion;
  }
};

export default mutations;
