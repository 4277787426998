/* eslint-disable no-unused-vars */
// Methods. NEVER update state data from an action/method
// Update state data with mutations
import * as type from "./mutation-types";

const actions = {
  toggleChatDialog({ commit, state }, show) {
    if (state.showChatDialog !== show) {
      commit(type.SHOW_CHAT_DIALOG, show);
    }
  },
};

export default actions;
