<template>
    <v-icon color="#3AC2D2 !important" :size="size">fas fa-crown</v-icon>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  props: {
    size: {
      default: 12
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters('subscriptions', ['hasPermission'])
  }
};
</script>