/* eslint-disable no-unused-vars */

import {
  ClickConversionTimeSchema,
  SalesPerDayAndTimeSchema,
  CategoryStatsSchema,
  ManagedReportsSchema,
  SignUpsSchema,
  BusinessInfluencerActivityStatsSchema
} from "@/helpers/schemas/moderator/stats.js";
import {
  ModeratorInfluencersSchema,
  InfluencerChannelApplicationSchema,
  ModeratorInfluencersChannelsSchema
} from "@/helpers/schemas/moderator/influencer.js";
import { CampaignDemoSchema } from "@/helpers/schemas/core/campaigns.js";
import { InfluencerPromotionCodeSchema } from "@/helpers/schemas/influencers/promotionCodes.js";
import { BusinessSchema } from "@/helpers/schemas/core/users.js";
import { createDiscountSchema, deleteDiscountSchema, resumeSubscriptionSchema, deleteSubscriptionScheduleSchema } from "@/helpers/schemas/core/subscriptions.js";

import {
  SubscriptionByStripeKeySchema,
  UpdateModeratorSalesSchema,
  ModeratorSubscriptionsSchema,
  ModeratorSingleSubscriptionSchema,
  SalesSummaryStatusesSchema,
  bulkRejectSaleSchema,
  TaxRatesSchema
} from "@/helpers/schemas/moderator/moderator.js";
import { CreateInvoiceSchema } from "@/helpers/schemas/core/invoice.js";

const actions = {
  getClickConversionTime({ commit }, params) {
    const data = {
      params: params,
      result: ClickConversionTimeSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("clickConversionTime", data).then(
        response => {
          resolve(response.data.clickConversionTime);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSalesPerDayAndTime({ commit }, params) {
    const data = {
      params: params,
      result: SalesPerDayAndTimeSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("salesPerDayAndTime", data).then(
        response => {
          resolve(response.data.salesPerDayAndTime);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  salesSummaryStatuses({ commit }, params) {
    const data = {
      params: params,
      result: SalesSummaryStatusesSchema,
      type: "SalesSummaryStatuses"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("salesSummaryStatuses", data).then(
        response => {
          resolve(response.data.salesSummaryStatuses);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  bulkRejectSales({ commit }, params) {
    const data = {
      params: params,
      result: bulkRejectSaleSchema,
      type: "BulkRejectSale"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("bulkRejectSales", data).then(
        response => {
          resolve(response.data.bulkRejectSales);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getCategoryStats({ commit }, params) {
    const data = {
      params: params,
      result: CategoryStatsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("categoryStats", data).then(
        response => {
          resolve(response.data.categoryStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getModeratorInfluencers({ commit }, params) {
    const data = {
      params: params,
      result: ModeratorInfluencersSchema,
      type: "InfluencersPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("moderatorInfluencers", data).then(
        response => {
          resolve(response.data.moderatorInfluencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getModeratorSubscriptions({ commit }, params) {
    const data = {
      params: params,
      result: ModeratorSubscriptionsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("subscriptions", data).then(
        response => {
          resolve(response.data.subscriptions);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getTaxRates({ commit }, params) {
    const data = {
      params: params,
      result: TaxRatesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("taxRates", data).then(
        response => {
          resolve(response.data.taxRates);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  
  getModeratorSingleSubscription({ commit }, params) {
    const data = {
      params: params,
      result: ModeratorSingleSubscriptionSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getModeratorSubscription", data).then(
        response => {
          resolve(response.data.getModeratorSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getModeratorInfluencersChannels({ commit }, params) {
    const data = {
      params: params,
      result: ModeratorInfluencersChannelsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("moderatorInfluencersChannels", data).then(
        response => {
          resolve(response.data.moderatorInfluencersChannels);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateSale({ dispatch }, params) {
    const data = {
      params: params,
      result: UpdateModeratorSalesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateSale", data).then(
        response => {
          resolve(response.data.updateSale);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getManagedReports({ commit }, params) {
    const data = {
      params: params,
      result: ManagedReportsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("managedReports", data).then(
        response => {
          resolve(response.data.managedReports);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSignUps({ commit }, params) {
    const data = {
      params: params,
      result: SignUpsSchema,
      type: "SignUps"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("signUps", data).then(
        response => {
          resolve(response.data.signUps.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setChannelApplication({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerChannelApplicationSchema,
      type: "InfluencerChannel"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("influencerChannelApplication", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createManagedReport({ dispatch }, params) {
    const data = {
      params: params,
      result: ManagedReportsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createManagedReport", data).then(
        response => {
          resolve(response.data.createManagedReport);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateManagedReport({ dispatch }, params) {
    const data = {
      params: params,
      result: ManagedReportsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateManagedReport", data).then(
        response => {
          resolve(response.data.updateManagedReport);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  addInvoice({ dispatch }, params) {
    const data = {
      params: params,
      result: CreateInvoiceSchema,
      type: "InvoiceCreate"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("invoice", data).then(
        response => {
          resolve(response.data.invoice);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSubscriptionByStripeKey({ commit }, params) {
    const data = {
      params: params,
      result: SubscriptionByStripeKeySchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("subscriptionByStripeKey", data).then(
        response => {
          resolve(response.data.subscriptionByStripeKey);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getBusinessActivityStats({ commit }, params) {
    const data = {
      params: params,
      result: BusinessInfluencerActivityStatsSchema,
      type: "BusinessActivityStats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("businessActivityStats", data).then(
        response => {
          resolve(response.data.businessActivityStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerActivityStats({ commit }, params) {
    const data = {
      params: params,
      result: BusinessInfluencerActivityStatsSchema,
      type: "InfluencerActivityStats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerActivityStats", data).then(
        response => {
          resolve(response.data.influencerActivityStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setBusinessApprovalCreate({ dispatch }, params) {
    const data = {
      params: params,
      result: CampaignDemoSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("businessApprovalCreate", data).then(
        response => {
          resolve(response.data.businessApprovalCreate);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setBusinessApprovalUpdate({ dispatch }, params) {
    const data = {
      params: params,
      result: BusinessSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("businessApprovalUpdate", data).then(
        response => {
          resolve(response.data.businessApprovalUpdate);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setPromotionCodeApprovalCreate({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerPromotionCodeSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("promotionCodeApprovalCreate", data).then(
        response => {
          resolve(response.data.promotionCodeApprovalCreate);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setPromotionCodeApprovalUpdate({ dispatch }, params) {
    const data = {
      params: params,
      result: BusinessSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("promotionCodeApprovalUpdate", data).then(
        response => {
          resolve(response.data.promotionCodeApprovalUpdate);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createDiscount({ dispatch }, params) {
    const data = {
      params: params,
      result: createDiscountSchema,
      type: "Discount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createDiscount", data).then(
        response => {
          resolve(response.data.createDiscount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  
  deleteDiscount({ dispatch }, params) {
    const data = {
      params: params,
      result: deleteDiscountSchema,
      type: "DeletedDiscount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteDiscount", data).then(
        response => {
          resolve(response.data.deleteDiscount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteSchedule({ dispatch }, params) {
    const data = {
      params: params,
      result: deleteSubscriptionScheduleSchema,
      type: "StripeSubscriptionsUpdateResponse"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteSubscriptionSchedule", data).then(
        response => {
          resolve(response.data.deleteSubscriptionSchedule);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  
  resumeSubscription({ dispatch }, params) {
    const data = {
      params: params,
      result: resumeSubscriptionSchema,
      type: "StripeSubscriptionsResponseData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("resumeSubscription", data).then(
        response => {
          resolve(response.data.resumeSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  }
};

export default actions;
