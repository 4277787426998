// Computed state properties
const getters = {
  hasSubscription: state => {
    return !!state.subscription;
  },
  hasPermission: (state) => (permission) => {
    return state.subscription ? state.subscription.permissions.includes(permission) : false;
  },
  getSetting: (state) => (setting) => {
    return (state.subscription && state.subscription.settings[setting]) || false;
  },
  getActiveSubscription: state => {
    return state.subscription;
  },
  getSubscriptionProducts: state => {
    return state.subscriptionProducts;
  },
};

export default getters;
