<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card style="overflow: hidden; height: 100vh; height: 100dvh;">
      <v-toolbar color="secondary" class="no-shadow border-bottom">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>

        <v-toolbar-title class="primary--text">
          Messages
        </v-toolbar-title>
      </v-toolbar>
      <!--100% height minus toolbar height-->
      <v-container fluid class="p-0" style="height: calc(100vh - 56px); height: calc(100dvh - 56px);">
        <iframe
          ref="iframe"
          :src="iframeUrl"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data: () => ({
    mobileNewChat: false,
    iframeUrl: process.env.VUE_APP_CHAT_IFRAME_URL,
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapState("core/chats", ["showChatDialog"]),
    showDialog: {
      get() {
        return this.showChatDialog;
      },
      set(newValue) {
        this.toggleChatDialog(newValue);
      },
    },
  },
  watch: {
    showChatDialog() {
      const liveChat = document.getElementById(
        "hubspot-messages-iframe-container"
      );
      const html = document.querySelector("html");
      if (html) {
        html.style.overflow = this.showChatDialog ? "hidden" : "auto";
      }
      if (liveChat) {
        if (this.showChatDialog && screen.width <= 480) {
          liveChat.style.visibility = "hidden";
        } else {
          liveChat.style.visibility = "visible";
        }
      }

      window.Intercom("update", {
        hide_default_launcher: this.showChatDialog,
      });

      /**
       * Sendbird Chat Integration
       *
       * Send the user's credentials to the iframe
       */
      if (this.showChatDialog) {
        this.$nextTick(() => {
          const iframe = this.$refs.iframe;
          const userType = capitalize(this.$authHelper.getUserType());
          const auth = {
            accessToken: this.$authHelper.getAccessToken(),
            userId: this.$authHelper.getUserId(),
            userType: `App\\Models\\${userType}`,
            refreshToken: this.$authHelper.getRefreshToken(),
            tokenExpiresAt: new Date(
              Number.parseInt(this.$authHelper.getTokenExpiresAt(), 10)
            ).toISOString(),
            createdAt: dayjs(this.user.created_at)
              .toDate()
              .toISOString(),
            email: this.user.email,
            fullName: `${this.user.first_name} ${this.user.last_name}`,
            shadowLogin: this.$authHelper.getShadowLogin(),
          };

          if (userType === "Business") {
            auth.businessUuid = this.user.business.uuid;
          }

          if (userType === "Influencer") {
            auth.influencerUuid = this.user.influencer.uuid;
          }

          iframe.onload = () => {
            const iframeWindow = iframe.contentWindow;

            iframeWindow.postMessage(
              {
                type: "MI_CREDENTIALS",
                payload: auth,
              },
              this.iframeUrl
            );
          };
        });
      }
    },
  },
  methods: {
    ...mapActions("core/chats", ["toggleChatDialog"]),
  },
};
</script>
