<template>
  <div class="ml-3">
    <div class="title">
      Choose account type
    </div>

    <p>
      We use Stripe to automatically pay out your commission
    </p>

    <v-radio-group
      v-model="payoutAccount.account_type"
      label="Is the payout for a company?"
    >
      <v-radio
        label="No - only to me personally"
        :value="2"
      ></v-radio>

      <v-radio label="Yes" :value="1"></v-radio>
    </v-radio-group>

    <v-radio-group
      v-model="payoutAccount.is_taxable"
      label="Is your company registeret for VAT?"
      :disabled="payoutAccount.account_type !== 1"
    >
      <v-radio label="No" :value="false"></v-radio>

      <v-radio label="Yes" :value="true"></v-radio>
    </v-radio-group>

    <v-text-field
      v-if="
        payoutAccount.account_type === 1 &&
          payoutAccount.is_taxable
      "
      v-model="payoutAccount.tax_id"
      label="VAT number"
      style="max-width: 300px;"
    ></v-text-field>

    <div class="mt-3 mb-4">
      <v-btn
        color="success"
        :disabled="! validTaxInfo"
        :loading="loading"
        @click="createPayoutAccount()"
        >GET STARTED</v-btn
      >
    </div>

    <i>
      By clicking <b>GET STARTED</b>, you agree to Stripe's
      <a href="https://stripe.com/legal" target="_blank">Services Agreement</a>
      and the
      <a href="https://stripe.com/connect-account/legal" target="_blank"
        >Stripe Connected Account Agreement.</a
      >
    </i>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    payoutAccount: {},
    loading: false
  }),
  computed: {
    validTaxInfo() {
      if (
        typeof this.payoutAccount.account_type === "undefined" ||
        (this.payoutAccount.account_type === 1 &&
          typeof this.payoutAccount.is_taxable === "undefined")
      ) {
        return false;
      }

      if (
        this.payoutAccount.account_type === 1 &&
        this.payoutAccount.is_taxable &&
        !this.payoutAccount.tax_id
      ) {
        return false;
      }

      return true;
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("influencer", { addPayoutAccount: "createPayoutAccount" }),

    createPayoutAccount() {
      this.loading = true;
      this.addPayoutAccount(this.payoutAccount)
        .then(() => {
          this.setSnackSuccess("Succes");
          this.getUserFromApi();
          this.loading = false;
        })
        .catch(() => {
          this.setSnackError("Something went wrong.");
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
