const rawUserSchema = `
  uuid
  email
  first_name
  last_name
  phone
  trial_ends_at
  card_last_four
  card_brand
  stripe_id
  created_at
  updated_at
  user_type
  avatar_url
  accounts {
    uuid
    account_type
  }
  user_settings {
    notification_email_campaign_end
    notification_email_campaign_invitation
    notification_email_campaign_start
    notification_email_new_sale
    notification_email_unread_chat_message
  }
  active_subscription {
    stripe_id
    trial_ends_at
    ends_at
    product {
      quantity
      price {
        stripe_id
        name
      }
    }
    is_active
    is_trialing
    is_canceled
    is_churned
  }
`;

export const ModeratorSchema = `{
  id
  is_developer 
  display_name 
  created_at 
  updated_at
  user {
    uuid
    first_name
    email
    last_name
    full_name
    active_subscription {
      stripe_id
      trial_ends_at
      ends_at
      product {
        quantity
        price {
          stripe_id
          name
        }
      }
      is_active
      is_trialing
      is_canceled
      is_churned
    }
  }
}`;

export const BusinessSchema = `{
  uuid
  company
  display_name
  vat
  phone
  website
  description
  webshop_system
  instagram_usernames
  heard_about_text
  heard_about
  managed_contract_url
  full_agency_service
  service_fee
  commission_invoice_cycle
  auto_charge_commission_invoice
  approval_days
  tracking_level
  pixel_approved_at
  promotion_code_tracking_approved_at
  created_at
  updated_at
  allowed_active_influencers
  allowed_active_campaigns
  agency {
    uuid
    name
    created_at
    updated_at
  }
  channel_category {
    id
    name
    display_name
    description
    icon
    created_at
    updated_at
  }
  user {
    uuid
    first_name
    email
    last_name
    full_name
    card_last_four
    created_at
    user_settings{
      notification_email_campaign_end
      notification_email_campaign_invitation
      notification_email_campaign_start
      notification_email_new_sale
      notification_email_unread_chat_message
    }
    active_subscription {
      stripe_id
      trial_ends_at
      ends_at
      next_period_at
      contract_ending_at
      product {
        name
        display_name
        quantity
        price {
          stripe_id
          name
        }
      }
      is_active
      is_trialing
      is_canceled
      is_churned
    }
  }
}`;
export const BusinesessSchema = `{
  response${BusinessSchema} 
  lastPage
  currentPage
  total
  perPage
}`;
export const InfluencerSchema = `{
  uuid
  status
  birthday
  gender
  created_at
  updated_at
  stripe_connect_key
  stripe_balance_available
  age_restrictions
  country {
    id
    name
    flag
    iso_3166_2
  }
  user {
    uuid
    email
    first_name
    last_name
    full_name
    phone
    avatar_url
  }
  payout_accounts {
    uuid
    active
    payouts_enabled_at
    transfers_enabled_at
    balance
    account_type
    contact_name
    address
    city
    postal_code
    company
    tax_id
    is_taxable
  }
  channels {
    uuid
    username
    description
    website
    profile_image_url
    followers
    impressions
    media_count
    active
    approved_at
    api_failed_at

    channel {
      uuid
      name
      display_name
      description
      icon
      created_at
      updated_at
      login_link
    }
    categories {
      id
      name
      display_name
      description
      icon
      created_at
      updated_at
    }
  }
  favorite_by_business {
    uuid
  }
}`;

export const InfluencersSchema = `{
  response${InfluencerSchema}
  lastPage
  currentPage
  total
  perPage
}`;

export const InfluencersAutocompleteSchema = `{
  response {
    uuid
    status
    birthday
    gender
    age_restrictions
    country {
      id
      name
      flag
      iso_3166_2
    }
    user {
      uuid
      email
      first_name
      last_name
      full_name
      avatar_url
    }
    channels {
      uuid
      username
      description
      website
      profile_image_url
      followers
      impressions
      media_count
      active
      channel {
        uuid
        name
        display_name
        description
        icon
        created_at
        updated_at
      }
    }
  }
  lastPage
  currentPage
  total
  perPage
}`;

export const AgencySchema = `{
  uuid
  name
  created_at
  updated_at
  businesses ${BusinessSchema}
  user{
    ${rawUserSchema}
  }
}`;

export const ModeratorUserSchema = `{
  ${rawUserSchema}
  moderator ${ModeratorSchema}
}`;

export const BusinessUserSchema = `{
  ${rawUserSchema}
  business ${BusinessSchema}
}`;

export const InfluencerUserSchema = `{
  ${rawUserSchema}
  influencer ${InfluencerSchema}
}`;

export const AgencyUserSchema = `{
  ${rawUserSchema}
  agency ${AgencySchema}
}`;

export const UpdateUserSchema = `{
  first_name
  last_name
  phone
  email
}`;

export const UploadAvatarSchema = `{
  uuid
  avatar_url
}`;

export const UpdateUserSettingSchema = `{
  notification_email_campaign_end
  notification_email_campaign_invitation
  notification_email_campaign_start
  notification_email_new_sale
  notification_email_unread_chat_message
}`;

export const ChatUserSchema = `{
  uuid
  first_name
  last_name
  agency{
    uuid
    name
    }
  business{
    uuid
    display_name
    }
}`;

export const updatePayoutAccountSchema = `{
  success
}`;