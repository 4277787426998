// Methods. NEVER update state data from an action/method
// Update state data with mutations
import * as type from "./mutation-types";

const actions = {
  setSelectedCountries({ commit }, countries) {
    commit(type.SET_SELECTED_COUNTRIES, countries);
  }
};

export default actions;