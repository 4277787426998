import axios from "axios";
import store from "@/store/main.js";

export function authHelper() {
  return {
    setTokensFromResponse(response) {
      this.setAccessToken(response.access_token);
      if (response.refresh_token) {
        this.setRefreshToken(response.refresh_token);
      } else {
        this.setRefreshToken(null);
      }
      if (response.shadow) {
        this.setShadowLogin(true);
      } else {
        this.setShadowLogin(false);
      }
      // API expires_at in secs - convert to ms
      this.setTokenExpiresAt(Date.now() + Number(response.expires_in * 1000));
      // This DB should eventually replace localStorage
      let db = require("./local_auth_database");
      db.setAuth(response);
    },
    setAccessToken(token) {
      localStorage.setItem("access_token", token);
      this.setApiHeaders();
    },
    getAccessToken() {
      return localStorage.getItem("access_token");
    },
    setRefreshToken(token) {
      localStorage.setItem("refresh_token", token);
    },
    setUserId(id) {
      localStorage.setItem("uuid", id);
    },
    setUserType(type) {
      localStorage.setItem("user_type", type);
    },
    getUserId() {
      return localStorage.getItem("uuid");
    },
    getUserType() {
      return localStorage.getItem("user_type");
    },
    getRefreshToken() {
      return localStorage.getItem("refresh_token");
    },
    setTokenExpiresAt(expire) {
      localStorage.setItem("token_expires_at", expire);
    },
    getTokenExpiresAt() {
      return localStorage.getItem("token_expires_at");
    },
    setShadowLogin(shadow) {
      localStorage.setItem("shadow_login", shadow);
    },
    getShadowLogin() {
      let isShadowLogin = localStorage.getItem("shadow_login");

      if (isShadowLogin) {
        return JSON.parse(isShadowLogin);
      }

      return false;
    },
    setApiHeaders() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.getAccessToken();
    },
    destroyTokens() {
      // Logout method
      // Should prob call delete token in API
      // localStorage.removeItem("uuid");
      localStorage.removeItem("user_type");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token_expires_at");
      localStorage.removeItem("shadow_login");
    },
    checkToken() {
      const tokenResult = new Promise((resolve, reject) => {
        // Token is not set
        if (!this.getAccessToken() || !this.getTokenExpiresAt()) {
          reject("Token data is null");
        } else {
          // If token isn't expired
          const isExpired = Date.now() > Number(this.getTokenExpiresAt());
          if (!isExpired) {
            resolve(true);
          } else {
            const params = {
              refresh_token: "" + this.getRefreshToken()
            };
            store.dispatch("core/auth/refreshToken", params).then(
              response => {
                this.setTokensFromResponse(response);
                resolve("New access token");
              },
              error => {
                reject("Can't renew access token");
                console.log(error);
              }
            );
          }
        }
      });
      return tokenResult;
    }
  };
}
