// eslint-disable-next-line no-undef
define(function() {
  // Read more at:
  // https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
  return {
    dbName: "AuthDatabase",
    dbVersion: 1,
    storeName: "AuthStore",
    storeKey: "auth",
    setAuth(authData) {
      let _this = this;

      // Set response info in indexedDB to use in service_worker
      let request = indexedDB.open(_this.dbName, _this.dbVersion);

      request.onsuccess = function(event) {
        // get database from event
        let db = event.target.result;
        // create transaction from database
        let transaction = db.transaction(_this.storeName, "readwrite");
        // get store from transaction
        let store = transaction.objectStore(_this.storeName);

        // Use some fake ID as a pointer
        authData.id = _this.storeKey;

        // Put into db
        store.put(authData);

        // count number of objects in store
        // store.count().onsuccess = function(event) {
        //     console.log('[Transaction - COUNT] number of products in store', event.target.result);
        // };
      };

      request.onupgradeneeded = function(event) {
        let db = event.target.result;
        db.createObjectStore(_this.storeName, { keyPath: "id" });
      };

      request.onerror = () => {
        console.error("[SetAuthDbError]", request.error);
      };
    },
    getAuth() {
      let _this = this;

      return new Promise(function(resolve, reject) {
        // Set response info in indexedDB to use in service_worker
        let request = indexedDB.open(_this.dbName, _this.dbVersion);

        request.onsuccess = function(event) {
          // get database from event
          let db = event.target.result;
          // create transaction from database
          let transaction = db.transaction(_this.storeName, "readwrite");
          // get store from transaction
          let store = transaction.objectStore(_this.storeName);

          // Get data
          store.get(_this.storeKey).onsuccess = function(event) {
            let data = event.target.result;

            if (data === undefined) {
              reject("No data");
            } else {
              resolve(data);
            }
          };
        };

        request.onupgradeneeded = function(event) {
          let db = event.target.result;
          db.createObjectStore(_this.storeName, { keyPath: "id" });
        };

        request.onerror = function(event) {
          console.error("[GetAuthDbError]", request.error);
          reject(event);
        };
      });
    }
  };
});
