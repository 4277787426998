import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("snackbar", [
      "setSnackInfo",
      "setSnackSuccess",
      "setSnackError"
    ])
  }
};
