export const SubscriptionSchema = `{
  product
  plan
  trial_ends_at
  ends_at
  on_trial
}`;
export const updateSelfSubscriptionSchema = `{
  data {
    product
    plan
    trial_ends_at
    ends_at
    on_trial
  }
}`;
export const UnfinishedSubscriptionSchema = `{
  status
  id
  latest_invoice {
    id
    customer
    payment_intent {
      id
      status
      client_secret
    }
  }
}`;
export const DiscountSchema = `{
  coupon
  object
  amount_off
  created
  currency
  duration
  duration_in_months
  livemode
  max_redemptions
  name
  percent_off
  redeem_by
  times_redeemed
  valid
}`;
export const SubscriptionUpdateSchema = `{
  key
  customer
  discount {
    id
    name
    percent_off
    amount_off
    max_redemptions
    duration
    duration_in_months
  }
  current_period_start
  current_period_end
  collection_method
  cancel_at_period_end
  canceled_at
  cancel_at
  ended_at
  quantity
  trial_start
  trial_end
  status
  plan {
    key
    nickname
    product {
      key
      name
    }
    amount
    currency
    interval
    interval_count
  }
}`;
export const PaymentIntentSchema = `{
  secret
}`;
export const PaymentCardsSchema = `{
  status
}`;
export const StripePlansSchema = `{
  id
  nickname
  product
  amount
  currency
  interval
  interval_count
}`;
export const createDiscountSchema = `{
  id
  object
  amount_off
  created
  currency
  duration
  duration_in_months
  livemode
  max_redemptions
  name
  percent_off
  redeem_by
  times_redeemed
  valid
}`;
export const deleteDiscountSchema = `{
  id
  object
  deleted
}`;
export const deleteSubscriptionScheduleSchema = `{
  key
  customer
  current_period_start
  current_period_end
  collection_method
  cancel_at_period_end
  canceled_at
  cancel_at
  ended_at
  quantity
  trial_start
  trial_end
}`;
export const resumeSubscriptionSchema = `{
  data {
    product
    plan
    trial_ends_at
    ends_at
    on_trial
  }
}`;