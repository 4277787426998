export const LoginSchema = `{
  access_token,
  expires_in,
  refresh_token,
  loginToken,
  user {
    uuid,
    user_type
    accounts {
        uuid
        account_type
    }
  }
}`;

export const RegisterInfluencerSchema = `{
  influencer_id
  code
  status
  message
  payload {
    link
    loginToken
  }
}`;
export const ForgotPasswordSchema = `{
  status
  message
}`;

export const registerBusinessSchema = `{
  code,
  status,
  message,
  payload {
    link
    loginToken
  }
}`;

export const RefreshTokenSchema = `{
  access_token
  expires_in
  refresh_token
}`;

export const loginTokenSchema = `{
  access_token
  expires_in
  user {
    uuid
    user_type
  }
  shadow
}`;

export const ShadowLoginSchema = `{
  token
  link
}`;
