<template>
  <v-form class="mt-15 pb-15 form-template business-form">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="py-0" data-cy="business-first_name">
          <span class="input-label">
            First name
          </span>
          <v-text-field
            outlined
            background-color="white"
            v-model="firstName"
            placeholder="First name"
            name="first_name"
            v-validate="'required'"
            :error-messages="
              validation.first_name
                ? validation.first_name
                : errors.collect('first_name')
            "
            @keyup="validation.first_name = null"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" class="py-0" data-cy="business-last_name">
          <span class="input-label">
            Last name
          </span>
          <v-text-field
            background-color="white"
            outlined
            v-model="lastName"
            placeholder="Last name"
            name="last_name"
            v-validate="'required'"
            :error-messages="
              validation.last_name
                ? validation.last_name
                : errors.collect('last_name')
            "
            @keyup="validation.last_name = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-email">
          <span class="input-label">
            Email
          </span>
          <v-text-field
            background-color="white"
            v-model="email"
            outlined
            placeholder="Email"
            type="email"
            name="email"
            v-validate="'required|email'"
            :error-messages="
              validation.email ? validation.email : errors.collect('email')
            "
            @keyup="validation.email = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-phone">
          <span class="input-label">
            Phone
          </span>
          <v-text-field
            background-color="white"
            v-model="phone"
            outlined
            placeholder="Phone"
            name="phone"
            v-validate="{
              required: true,
              regex: /^(\+){0,1}[0-9]+$/,
              excluded: '00000000',
            }"
            :error-messages="
              validation.phone ? validation.phone : errors.collect('phone')
            "
            @keyup="validation.phone = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-password">
          <span class="input-label">
            Password
          </span>

          <v-text-field
            v-model="password"
            background-color="white"
            outlined
            type="password"
            placeholder="Password"
            name="Password"
            v-validate="'required|min:6'"
            :error-messages="errors.collect('Password')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0" data-cy="business-company">
          <span class="input-label">
            Company
          </span>
          <v-text-field
            v-model="company"
            background-color="white"
            outlined
            placeholder="Company"
            name="Company"
            v-validate="'required'"
            :error-messages="errors.collect('Company')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0" data-cy="business-cvr">
          <span class="input-label">
            VAT identification number
          </span>
          <v-text-field
            v-model="vat"
            background-color="white"
            outlined
            placeholder="VAT"
            name="VAT"
            v-validate="{
              required: true,
              regex: /^([a-zA-Z]{0,4})([0-9]{4,18})$/,
            }"
            :error-messages="errors.collect('VAT')"
            messages="Example format: DKxxxxxxxx replacing x's with your VAT number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-website">
          <span class="input-label">
            Website
          </span>
          <v-text-field
            background-color="white"
            v-model="website"
            v-validate="'required|url'"
            name="Website"
            :error-messages="errors.collect('Website')"
            outlined
            placeholder="Website"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-shopSystem">
          <span class="input-label d-block">
            Name of web shop system
          </span>
          <v-select
            v-model="shopSystem"
            :items="shopSystemList"
            background-color="white"
            outlined
            placeholder="Select"
            data-cy="business-shopSystem-input"
            dense
            name="Webshop"
            v-validate="'required'"
            :error-messages="errors.collect('Webshop')"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-if="shopSystem === 'other'"
            v-model="shopSystemText"
            background-color="white"
            outlined
            placeholder="Name of web shop system"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="business-heard-about">
          <span class="input-label d-block">
            Where did you hear about us?
          </span>
          <v-select
            v-model="heardAbout"
            outlined
            :items="heardAboutList"
            data-cy="business-heard-about-input"
            background-color="white"
            name="Hørt"
            v-validate="'required'"
            :error-messages="errors.collect('Hørt')"
            placeholder="Where did you hear about us?"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <div v-if="heardAbout === 'other'">
            <span class="input-label">
              Where did you hear about us?
            </span>
            <v-text-field
              background-color="white"
              v-model="heardAboutText"
              outlined
              placeholder="Where did you hear about us?"
            ></v-text-field>
          </div>
          <div v-if="heardAbout === 'referral'">
            <span class="input-label">
              Who referred you?
            </span>
            <v-text-field
              background-color="white"
              v-model="heardAboutText"
              outlined
              placeholder="Who referred you?"
            ></v-text-field>
          </div>
          <div v-if="heardAbout === 'influencer'">
            <span class="input-label">
              Which influencer referred you?
            </span>
            <v-text-field
              background-color="white"
              v-model="heardAboutText"
              outlined
              placeholder="Which influencer referred you?"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0 custom-checkbox" data-cy="business-terms">
          <v-checkbox
            name="Terms"
            v-validate="'required:true'"
            :error-messages="errors.collect('Terms')"
            v-model="terms"
            color="#013138"
          >
            <template v-slot:label>
              I accept&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://makeinfluence.com/dk/virksomhed/betingelser"
                @click.stop
                class="custom-link accent--text"
              >
                the terms
              </a>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-checkbox
            color="#013138"
            v-model="newsletter"
            label="I'd like to sign up to newsletter"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row class="mr-4 mt-15 mx-auto" data-cy="business-submit">
        <v-btn
          class="mx-auto deepgreenbtn p-4"
          large
          :loading="saveLoading"
          @click="save()"
          min-width="60"
        >
          Create account
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { getUserTypeRedirectUrl } from "../../helpers/get-user-type-redirect-url";
import mi_tracking from "../../helpers/mixins/mi_tracking";

export default {
  mixins: [mi_tracking],
  data: () => ({
    saveLoading: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: null,
    password: null,
    newsletter: false,
    company: "",
    terms: false,
    heardAbout: "",
    website: "",
    shopSystem: "",
    shopSystemText: "",
    vat: null,
    heardAboutText: "",
    heardAboutList: [
      { value: "linkedin", text: "Linkedin" },
      { value: "facebook", text: "Facebook" },
      { value: "instagram", text: "Instagram" },
      { value: "google", text: "Google" },
      { value: "media", text: "Media" },
      { value: "referral", text: "Referral" },
      { value: "influencer", text: "Influencer" },
      { value: "presentation", text: "Presentation" },
      { value: "direct", text: "Direct" },
      { value: "other", text: "Andet" },
    ],
    shopSystemList: [
      { value: "Dandomain", text: "Dandomain" },
      { value: "Magento", text: "Magento" },
      { value: "Prestashop", text: "Prestashop" },
      { value: "Scannet", text: "Scannet" },
      { value: "Shopify", text: "Shopify" },
      { value: "Smartweb", text: "Smartweb" },
      { value: "Wannafind", text: "Wannafind" },
      { value: "Woocommerce", text: "Woocommerce" },
      { value: "other", text: "Andet" },
    ],
    validation: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
    },
  }),
  methods: {
    ...mapActions("core/auth", ["registerBusiness"]),
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveLoading = true;

          const params = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            phone: this.phone.replace("+", ""),
            company: this.company,
            vat: this.vat,
            website: this.website,
            webshop_system: this.shopSystem,
            webshop_system_text: this.shopSystemText,
            heard_about: this.heardAbout,
            heard_about_text: this.heardAboutText,
            password: this.password,
            newsletter: this.newsletter,
            terms: this.terms,
          };
          // check protocol
          const website = params.website;
          if (website.includes("http://") || website.includes("https://")) {
            params.website = website;
          } else {
            params.website = `http://${website}`;
          }
          const loginParams = {
            username: this.email,
            password: this.password,
          };
          this.registerBusiness(params).then(
            ({ data }) => {
              const redirectUrl = getUserTypeRedirectUrl("business");

              if (redirectUrl) {
                redirectUrl.searchParams.set(
                  "token",
                  data.registerBusiness.payload.loginToken
                );
                redirectUrl.searchParams.set("shadow", "false");

                window.location.href = redirectUrl;

                return;
              }

              if (data.registerBusiness.code === 200) {
                const url = window.location.pathname;
                if (this.$isProduction) {
                  window.fbq("trackCustom", "PageView", {
                    url: `${url}/success`,
                  });
                  window.fbq("track", "BusinessLead");
                  //  this.miTrackConversion();
                }

                let redirectOptions = {
                  referral_type: this.$route.query.referral_type,
                  referral_value: this.$route.query.referral_value,
                };

                setTimeout(
                  () => this.signIn(loginParams, redirectOptions),
                  1000
                );
              }
            },
            (errors) => {
              const arr = this.$errorHandler.getValidationErrors(errors);
              this.validation = {
                email: this.$errorHandler.getErrorMessage(arr, "email"),
                phone: this.$errorHandler.getErrorMessage(arr, "phone"),
                first_name: this.$errorHandler.getErrorMessage(
                  arr,
                  "first_name"
                ),
                last_name: this.$errorHandler.getErrorMessage(arr, "last_name"),
              };
              this.setSnackError("Invalid details");
              this.saveLoading = false;
            }
          );
        }
      });
    },
  },
  mounted() {
    if (this.$isProduction) {
      window.fbq("trackCustom", "BusinessATC");
      window.fbq("trackCustom", "BusinessViewContent");
    }
  },
  created() {
    this.firstName = this.$route.query.first_name;
    this.lastName = this.$route.query.last_name;
    this.email = this.$route.query.email;
    this.phone = this.$route.query.phone;
  },
};
</script>
<style lang="scss" scoped>
.v-input__slot {
  min-height: 40px !important;
}

.custom-link {
  font-family: "HindRegular", sans-serif;
  color: #88cfd8;
  text-decoration: none;
}
</style>
