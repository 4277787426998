import * as type from "./mutation-types";

const mutations = {
    [type.SET_PLAYING](state, payload) {
        state.playing = payload;
    },
    [type.SET_MUTED](state, payload) {
        state.muted = payload;
    }
};

export default mutations;