export const TransfersSchema = `{
    data{
        created_at
        currency
        amount
        vat_total
        total
    }
}`;
export const PayoutsSchema = `{
    data{
        status
        created_at
        arrival_at
        currency
        amount
        vat
        total
        stripe_key
    }
    last_payout_id
}`;
export const PayoutAccountSchema = `{
    uuid
    active
    status
    account_type
    payouts_enabled_at
    verification_failed_at
    tax_id
    is_taxable
}`;
export const PayoutAccountLinkSchema = `{
    url
}`;

export const ExternalPayoutAccountSchema = `{
    data{
        id
        object
        account
        account_holder_name
        account_holder_type
        available_payout_methods
        bank_name
        country
        currency
        default_for_currency
        fingerprint
        last4
        routing_number
        status
    }
}`;
export const CreateConnectAccountPayoutSchema = `{
    id
    amount
    arrival_date
    currency
    description
    statement_descriptor
    status
    automatic
    balance_transaction
    created
    destination
    failure_balance_transaction
    failure_code
    type
    method
    source_type
    original_payout
    reversed_by
    livemode
    failure_message
}`;
