let routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "Dashboard" },
    component: () => import("../../components/influencer/pages/Index"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profil" },
    component: () => import("../../components/common/profile/Profile.vue"),
  },
  {
    path: "/payout-account",
    name: "payout_account",
    meta: { title: "Payout info" },
    component: () => import("../../components/influencer/pages/PayoutAccount"),
  },
  // Old route. This name should be phased out
  {
    path: "/channels",
    name: "channel.index",
    meta: { title: "Influencer channels" },
    component: () =>
      import("../../components/influencer/pages/InfluencerChannels"),
  },
  {
    path: "/channels",
    name: "channels",
    component: () =>
      import("../../components/influencer/pages/InfluencerChannels"),
  },
  {
    path: "/campaigns",
    name: "campaigns",
    meta: { title: "Find campaigns" },
    component: () => import("../../components/influencer/pages/Campaigns"),
  },
  {
    path: "/campaign/:id",
    name: "campaign",
    meta: { title: "Campaign info" },
    component: () => import("../../components/pages/CampaignPage"),
  },
  {
    path: "/my-campaigns",
    name: "my.campaigns",
    meta: { title: "My campaigns" },
    component: () => import("../../components/pages/MyCampaignsPage"),
  },
  {
    path: "/channel/create",
    name: "channel.create",
    meta: { title: "Add influencer channel" },
    component: () =>
      import("../../components/influencer/pages/CreateInfluencerChannel"),
  },
  {
    path: "/channel/:id",
    name: "channel",
    meta: { title: "Add influencer channel" },
    component: () =>
      import("../../components/influencer/pages/InfluencerChannel"),
  },
  {
    path: "/report/overall",
    name: "report.overall",
    meta: { title: "Summarized report" },
    component: () =>
      import("../../components/influencer/pages/report/OverallReport"),
  },
  {
    path: "/report/daily",
    name: "report.daily",
    meta: { title: "Daily report" },
    component: () =>
      import("../../components/influencer/pages/report/DailyReport"),
  },
  {
    path: "/report/sales",
    name: "report.sales",
    meta: { title: "Sales report" },
    component: () =>
      import("../../components/influencer/pages/report/SalesReport"),
  },
  {
    path: "/report/commission",
    name: "report.commission",
    meta: { title: "Commission report" },
    component: () =>
      import("../../components/influencer/pages/report/CommissionReport"),
  },
  {
    path: "/report/business",
    name: "report.business",
    component: () =>
      import("../../components/influencer/pages/report/BusinessReport"),
  },
  {
    path: "/report/campaign",
    name: "report.campaign",
    component: () =>
      import("../../components/influencer/pages/report/CampaignReport"),
  },
  {
    path: "/report/instagram",
    name: "report.instagram",
    meta: { title: "Instagram report" },
    component: () =>
      import("../../components/influencer/pages/report/InstagramReport"),
  },
  {
    path: "/payouts",
    name: "payouts",
    component: () => import("../../components/influencer/pages/Payouts"),
  },
  {
    path: "/promotion-codes",
    name: "influencer_promotion_codes",
    meta: { title: "Payouts" },
    component: () =>
      import("../../components/common/pages/InfluencerPromotionCodes"),
  },
  {
    path: "/tracking-links",
    name: "tracking_links",
    component: () => import("../../components/influencer/pages/TrackingLinks"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: { title: "Logout" },
    component: () => import("../../components/app/Logout"),
  },
  {
    path: "/*",
    meta: { title: "Page not found" },
    component: () => import("../../components/influencer/pages/404"),
  },
];

export default routes;
