export const InfluencerChannelPostsSchema = `{
    lastPage
    currentPage
    limit
    total
    data {
        uuid
        posted_at
        caption
        mention_tags
        hashtags
        has_promotion
        has_thumbnail
        has_comment_enabled
        impressions
        reach
        replies
        likes
        comments
        saves
        exits
        taps_backward
        taps_forward
        engagement
        media_type
        media_product_type
        influencer_channel {
            uuid
            username
            influencer {
                uuid
                gender
                birthday
                user {
                    uuid
                    first_name
                    last_name
                    full_name
                    avatar_url
                }
            }
        }
        businesses {
            uuid
            display_name
        }
        campaigns {
            uuid
            name
        }
        files {
            uuid
            url
        }
    }
}`;

export const AttachInfluencerChannelPostsSchema = `{
    uuid
    businesses {
        uuid
        display_name
    }
    campaigns {
        uuid
        name
    }
}`;

export const DetachInfluencerChannelPostsSchema = AttachInfluencerChannelPostsSchema;