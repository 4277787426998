export const SystemSettingsSchema = `{
  app_env
  app_domain
  stripe_key
  asset_key
}`;

export const ErrorSchema = `{
  error_code: code
  error_data: data {
    field
    errors
  }
}`;
