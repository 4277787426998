export function getNotificationMeta(_this, meta, notification) {
  if (notification.type.includes("DemoNotificationMessage")) {
    meta.title = "Demo message";
    meta.icon = "fal fa-question";
  } else if (notification.type.includes("CampaignApplicationNotification")) {
    meta.title = "New campaign application";
    meta.icon = "fal fa-bullhorn";

    let campaignId = 0;

    if (notification.data.campaign) {
      campaignId = notification.data.campaign.uuid;
    } else {
      campaignId = notification.data.campaign_id;
    }

    let props = _this.$router.resolve({
      name: "campaign",
      params: {id: campaignId}
    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignInvitationAcceptedNotification")) {
    meta.title = "Campaign invitation accepted";
    meta.icon = "fal fa-check";

    let campaignId = 0;

    if (notification.data.campaign) {
      campaignId = notification.data.campaign.uuid;
    } else {
      campaignId = notification.data.campaign_id;
    }

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: campaignId }
    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignInvitationDeclinedNotification")) {
    meta.title = "Campaign invitation rejected";
    meta.icon = "fal fa-times";

    let campaignId = 0;

    if (notification.data.campaign) {
      campaignId = notification.data.campaign.uuid;
    } else {
      campaignId = notification.data.campaign_id;
    }

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: campaignId }
    });

    meta.link = props.href;
  } else if (notification.type.includes("PaymentInfoNotification")) {
    meta.title = "Missing payment info";
    meta.icon = "fal fa-exclamation";

    // Get full link
    let props = _this.$router.resolve({
      name: "payout_account"
    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignInvitationNotification")) {
    meta.title = "Campaign invitation";
    meta.icon = "fal fa-envelope";

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: notification.data.campaign_id }

    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignStartNotification")) {
    meta.title = "Campaign starting soon";
    meta.icon = "fal fa-exclamation";

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: notification.data.campaign_id }
    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignEndNotification")) {
    meta.title = "Campaign ends soon";
    meta.icon = "fal fa-exclamation";

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: notification.data.campaign_id }
    });

    meta.link = props.href;
  } else if (notification.type.includes("CampaignRejectionNotification")) {
    meta.title = "Campaign invitation rejected";
    meta.icon = "fal fa-times";

    // Get full link
    let props = _this.$router.resolve({
      name: "campaign",
      params: { id: notification.data.campaign_id }
    });

    meta.link = props.href;
  } else if (notification.type.includes("NewBusinessNotification")) {
    meta.title = "New brand - " + notification.data.business.company;
    meta.icon = "fal fa-store-alt";

    // Get full link
    let props = _this.$router.resolve({
      name: "business",
      params: { id: notification.data.business.uuid }
    });

    meta.link = props.href;
  } else if (notification.type.includes("NewInfluencerApplicationNotification")) {
    meta.title =
      "Ny influencer - " + notification.data.influencerChannel.username;
    meta.icon = "fab fa-instagram";

    // Get full link
    let props = _this.$router.resolve({
      name: "application.influencer"
    });

    meta.link = props.href;
  }

  return meta;
}
