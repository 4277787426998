// Methods. NEVER update state data from an action/method
// Update state data with mutations
import * as type from "./mutation-types";

const actions = {
  setSettings({ commit }, settingsObj) {
    commit(type.SET_SETTINGS, settingsObj);
  }
};

export default actions;
