<template>
  <v-container fluid>
    <!--Influencer actions-->
    <template v-if="user.influencer">
      <influencer-signup-stepper
        v-if="hasInfluencerSignupActions"
      ></influencer-signup-stepper>
      <!--Lost API access-->
      <div v-else-if="channelApiExpired >= 14">
        <the-api-expired-channels-action></the-api-expired-channels-action>
      </div>
      <!--Missing API categories-->
      <div v-else-if="channelsMissingCategories.length > 0">
        <div class="headline mb-3">Choose categories</div>
        <v-container fluid grid-list-lg class="p-0">
          <v-layout wrap>
            <v-flex
              v-for="influencerChannel in channelsMissingCategories"
              :key="influencerChannel.id"
              lg4
              md6
              xs12
              class="flex-card"
              height="100%"
            >
              <influencer-channel-card
                :influencer-channel="influencerChannel"
                class="grow"
                @input="getUserFromApi()"
              ></influencer-channel-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </template>
    <!--Business actions-->
    <template v-if="user.business">
      <the-business-invoice-information
        v-if="businessActionRequired"
      ></the-business-invoice-information>
    </template>
    <v-flex xs12 class="text-right mt-3">
      <v-btn color="primary" class="mr-0" text @click="logOut">Log out</v-btn>
    </v-flex>
  </v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TheApiExpiredChannelsAction from "./influencer-actions/TheApiExpiredChannelsAction";
import InfluencerChannelCard from "../../cards/InfluencerChannelCard";
import TheBusinessInvoiceInformation from "./business-actions/TheBusinessInvoiceInformation";
import InfluencerSignupStepper from "../../influencer/steppers/influencer-signup-stepper/InfluencerSignupStepper";

export default {
  components: {
    InfluencerSignupStepper,
    TheBusinessInvoiceInformation,
    InfluencerChannelCard,
    TheApiExpiredChannelsAction
  },
  computed: {
    ...mapGetters("core/auth", [
      "missingChannels",
      "noChannelsApproved",
      "channelApiExpired",
      "channelsMissingCategories",
      "businessActionRequired"
    ]),
    ...mapState("core/auth", ["user"]),
    hasInfluencerSignupActions() {
      if (!this.user.influencer) {
        return;
      }

      let activePayoutAccounts = this.user.influencer.payout_accounts.filter(
        item => item.active
      );

      return (
        this.missingChannels ||
        this.noChannelsApproved ||
        activePayoutAccounts.length <= 0
      );
    }
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi", "logout"]),
    logOut() {
      this.logout();
      this.$router.push("/login");
    }
  }
};
</script>
