<template>
  <v-dialog v-model="showDialog" persistent max-width="500px">
    <div class="subscription-dialog-wrapper">
      <button class="subscription-dialog-close" @click="showDialog = false">
        <v-icon small>fas fa-times</v-icon>
      </button>
      <create-subscription-stepper
        first-back-button
        @back="selectedProduct = null"
      ></create-subscription-stepper>
    </div>
  </v-dialog>
</template>
<style scoped>
  .subscription-dialog-wrapper {
    position: relative;
  }
  button.subscription-dialog-close {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 100;
    width: 25px;
    height: 25px;
  }
  button.subscription-dialog-close .v-icon {
    color: var(--v-primary-lighten2) !important;
  }
</style>
<script>
import CreateSubscriptionStepper from "../../../business/steppers/CreateSubscriptionStepper";
import { mapState, mapActions } from "vuex";

export default {
  components: { CreateSubscriptionStepper },
  data: () => ({
    selectedProduct: null,
  }),
  computed: {
    ...mapState("subscriptions", ["showSubscriptionDialog"]),
    showDialog: {
      get() {
        return this.showSubscriptionDialog;
      },
      set(value) {
        this.setShowSubscriptionDialog(value);
      }
    }
  },
  methods: {
    ...mapActions("subscriptions", [
      "setShowSubscriptionDialog",
    ]),
  },
  created() {
    this.trackVirtualPageView("subscription-dialog");
  }
};
</script>
