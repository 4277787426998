<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Invoice details</div>
        <div class="subtitle-1 light-grey">
          Select the information to be included on all invoices
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-text-field
        label="Email"
        name="email"
        v-model="invoiceAccount.email"
        v-validate="'required|email'"
        :error-messages="validation.email ? validation.email : errors.collect('email')"
        @keyup="validation.email = null"
      ></v-text-field>

      <v-text-field
        label="Company"
        name="name"
        v-validate="'required'"
        v-model="invoiceAccount.name"
        :error-messages="validation.name ? validation.name : errors.collect('name')"
        @keyup="validation.name = null"
      ></v-text-field>

      <v-text-field
        label="VAT"
        name="vat"
        v-validate="'required'"
        v-model="invoiceAccount.vat"
        :error-messages="validation.vat ? validation.vat : errors.collect('vat')"
        @keyup="validation.vat = null"
      ></v-text-field>

      <v-text-field
        label="Address"
        name="address"
        v-validate="'required'"
        v-model="invoiceAccount.address"
        :error-messages="validation.address ? validation.address : errors.collect('address')"
        @keyup="validation.address = null"
      ></v-text-field>

      <v-text-field
        label="Postal code"
        type="number"
        name="postal_code"
        v-validate="'required'"
        v-model="invoiceAccount.postal_code"
        :error-messages="validation.postal_code ? validation.postal_code : errors.collect('postal_code')"
        @keyup="validation.postal_code = null"
      ></v-text-field>

      <v-text-field
        label="City"
        name="city"
        v-validate="'required'"
        v-model="invoiceAccount.city"
        :error-messages="validation.city ? validation.city : errors.collect('city')"
        @keyup="validation.city = null"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="save()" :loading="loading">
        Continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    loading: false,
    invoiceAccount: {},
    validation: {
      email: null,
      name: null,
      vat: null,
      address: null,
      postal_code: null,
      city: null
    }
  }),
  computed: {
    ...mapState("core/auth", ["user"])
  },
  methods: {
    ...mapActions("core/auth", ["getUserFromApi"]),
    ...mapActions("core", ["updateInvoiceAccount"]),
    save() {
      this.loading = true;
      this.updateInvoiceAccount(this.invoiceAccount).then(
        () => {
          location.reload();
        },
        errors => {
          const arr = this.$errorHandler.getValidationErrors(errors);
          this.validation = {
            email: this.$errorHandler.getErrorMessage(arr, "email"),
            name: this.$errorHandler.getErrorMessage(arr, "name"),
            vat: this.$errorHandler.getErrorMessage(arr, "vat"),
            address: this.$errorHandler.getErrorMessage(arr, "address"),
            postal_code: this.$errorHandler.getErrorMessage(arr, "postal_code"),
            city: this.$errorHandler.getErrorMessage(arr, "city")
          };
          this.setSnackError("Something went wrong");
          this.loading = false;
        }
      );
    }
  },
  created() {
    this.trackVirtualPageView("business-signup-invoice-information");
    this.invoiceAccount.email = this.user.email;
    this.invoiceAccount.name = this.user.business.company;
    this.invoiceAccount.vat = this.user.business.vat;
  }
};
</script>
