import { mapActions } from "vuex";
import {
  getUserType,
  getUserTypeRedirectUrl,
} from "../get-user-type-redirect-url";

export default {
  methods: {
    ...mapActions("core/auth", ["login"]),
    signIn(params, redirectOptions = {}) {
      this.login(params).then(
        (response) => {
          const token = response.data.login;
          const userId = response.data.login.user.uuid;
          const userType = response.data.login.user.user_type.substring(11);
          const userTypeForRedirectCheck = getUserType(userType);
          const userTypeRedirectUrl = getUserTypeRedirectUrl(
            userTypeForRedirectCheck
          );

          if (userTypeRedirectUrl) {
            userTypeRedirectUrl.searchParams.set("token", token.loginToken);
            userTypeRedirectUrl.searchParams.set("shadow", "false");

            window.location.href = userTypeRedirectUrl;

            return;
          }

          this.$authHelper.setTokensFromResponse(token);
          this.$authHelper.setUserId(userId);
          this.$authHelper.setUserType(userType);

          // Default url
          let redirectUrl = this.$route.path;

          // If custom redirect url
          if (redirectOptions.url) {
            redirectUrl = redirectOptions.url;
          }

          // If invalid url
          if (
            redirectUrl.includes("logout") ||
            redirectUrl.includes("login") ||
            redirectUrl.includes("signup")
          ) {
            redirectUrl = "/";
          }

          if (redirectOptions.referral_type) {
            // Has multiple params?
            if (redirectUrl.includes("?")) {
              redirectUrl += "&";
            } else {
              redirectUrl += "?";
            }

            redirectUrl += `referral_type=${redirectOptions.referral_type}`;
            redirectUrl += `&referral_value=${redirectOptions.referral_value}`;
          }

          window.location.replace(redirectUrl);

          this.saveLoading = false;
        },
        () => {
          this.setSnackError("Invalid details");
          this.saveLoading = false;
        }
      );
    },
  },
};
