/*!
 * Define app theme depens of user type
 */
export function defineAppTheme(themes) {
  const type = localStorage.getItem("user_type");
  switch (type) {
    case "Moderator":
      return themes.moderator;
    case "Agency":
      return themes.agency;
    case "Influencer":
      return themes.influencer;
    case "Business":
      return themes.business;
    default:
      return themes.influencer;
  }
}
