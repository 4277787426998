// Sometimes we may want to group all the components nested under the same route into the same async chunk.
// To achieve that we need to use named chunks by providing a chunk name using a special comment
// syntax (requires webpack > 2.4):
// EMAPLE:
// const Foo = () => import(/* webpackChunkName: "group-foo" */ './Foo.vue')
let routes = [
  {
    path: "/",
    meta: { title: "Dashboard" },
    name: "home",
    component: () => import("../../components/agency/pages/Index"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../../components/common/profile/Profile.vue"),
  },
  {
    path: "/businesses",
    name: "businesses",
    component: () => import("../../components/agency/pages/Businesses"),
  },
  {
    path: "/business/:id",
    name: "business",
    component: () => import("../../components/agency/pages/Business"),
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("../../components/pages/MyCampaignsPage"),
  },
  {
    path: "/campaign/create/:id",
    name: "campaign.create",
    component: () => import("../../components/pages/CreateCampaignPage"),
  },
  {
    path: "/campaign/:id",
    name: "campaign",
    component: () => import("../../components/pages/CampaignPage"),
  },
  {
    path: "/influencers",
    name: "influencers",
    component: () => import("../../components/pages/InfluencersPage"),
  },
  {
    path: "/influencer/:id",
    name: "influencer",
    component: () => import("../../components/common/pages/InfluencerView"),
  },
  {
    path: "/report",
    component: () => import("../../components/agency/pages/report/Report"),
    children: [
      {
        path: "daily",
        name: "report.daily",
        meta: { title: "Daily report" },
        component: () => import("../../components/agency/pages/report/Daily"),
      },
      {
        path: "sales",
        name: "report.sales",
        meta: { title: "Sales report" },
        component: () => import("../../components/agency/pages/report/Sales"),
      },
      {
        path: "influencer",
        name: "report.influencer",
        meta: { title: "Influencer report" },
        component: () =>
          import("../../components/agency/pages/report/Influencer"),
      },
      {
        path: "campaign",
        name: "report.campaign",
        meta: { title: "Campaign report" },
        component: () =>
          import("../../components/agency/pages/report/Campaign"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    meta: { title: "" },
    component: () => import("../../components/app/Logout"),
  },
  {
    path: "/*",
    name: "404",
    meta: { title: "Siden blev ikke fundet" },
    component: () => import("../../components/agency/pages/404"),
  },
];

export default routes;
