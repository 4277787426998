/* eslint-disable no-unused-vars */

import {
  ChannelsSchema,
  InfluencerChannelsSchema,
  ChannelCategoriesSchema
} from "@/helpers/schemas/influencers/channels.js";
import { InfluencerCommissionSchema } from "@/helpers/schemas/influencers/influencer.js";
import { InfluencerPromotionCodeSchema } from "@/helpers/schemas/influencers/promotionCodes.js";
import {
  PayoutsSchema,
  TransfersSchema,
  PayoutAccountSchema,
  PayoutAccountLinkSchema,
  ExternalPayoutAccountSchema,
  CreateConnectAccountPayoutSchema
} from "@/helpers/schemas/influencers/payouts.js";
import { InfluencerSchema } from "@/helpers/schemas/core/users.js";
import { TransactionReportPaginated } from "@/helpers/schemas/influencers/transactions.js";

const actions = {
  getInfluencerChannels({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerChannelsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannels", data).then(
        response => {
          resolve(response.data.influencerChannels);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getChannels({ dispatch }, params) {
    const data = {
      params: params,
      result: ChannelsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("channels", data).then(
        response => {
          resolve(response.data.channels);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSingleChannel({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerChannelsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannel", data).then(
        response => {
          resolve(response.data.influencerChannel);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getChannelCategories({ dispatch }, params) {
    const data = {
      params: params,
      result: ChannelCategoriesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("channelCategories", data).then(
        response => {
          resolve(response.data.channelCategories);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateInfluencerChannel({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerChannelsSchema,
      type: "InfluencerChannel"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateInfluencerChannel", data).then(
        response => {
          resolve(response.data.updateInfluencerChannel);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateInfluencer({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerSchema,
      type: "Influencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateInfluencer", data).then(
        response => {
          resolve(response.data.updateInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateInfluencerCountry({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerSchema,
      type: "Influencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateInfluencerCountry", data).then(
        response => {
          resolve(response.data.updateInfluencerCountry);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getinfluencerCommission({ dispatch }, params) {
    const data = {
      params,
      result: InfluencerCommissionSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerCommission", data).then(
        response => {
          resolve(response.data.influencerCommission);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerPromotionCodes({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerPromotionCodeSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql
        .query("getInfluencerPromotionCodesForInfluencer", data)
        .then(
          response => {
            resolve(response.data.getInfluencerPromotionCodesForInfluencer);
          },
          error => {
            reject(error);
          }
        );
    });
  },
  getPayouts({ dispatch }, params) {
    const data = {
      params: params,
      result: PayoutsSchema,
      type: "PayoutData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getPayouts", data).then(
        response => {
          resolve(response.data.getPayouts);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getTransfers({ dispatch }, params) {
    const data = {
      params: params,
      result: TransfersSchema,
      type: "TransferData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getTransfers", data).then(
        response => {
          resolve(response.data.getTransfers.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getPayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: PayoutAccountSchema,
      type: "PayoutAccount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getPayoutAccount", data).then(
        response => {
          resolve(response.data.getPayoutAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createPayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: PayoutAccountSchema,
      type: "PayoutAccount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createPayoutAccount", data).then(
        response => {
          resolve(response.data.createPayoutAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createPayoutAccountLink({ dispatch }, params) {
    const data = {
      params: params,
      result: PayoutAccountLinkSchema,
      type: "PayoutAccountLink"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createPayoutAccountLink", data).then(
        response => {
          resolve(response.data.createPayoutAccountLink);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createExternalPayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: ExternalPayoutAccountSchema,
      type: "ExternalPayoutAccountData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createExternalPayoutAccount", data).then(
        response => {
          resolve(response.data.createExternalPayoutAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getExternalPayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: ExternalPayoutAccountSchema,
      type: "ExternalPayoutAccountData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getExternalPayoutAccount", data).then(
        response => {
          resolve(response.data.getExternalPayoutAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updatePayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: PayoutAccountSchema,
      type: "PayoutAccount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updatePayoutAccount", data).then(
        response => {
          resolve(response.data.updatePayoutAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createConnectAccountPayout({ dispatch }, params) {
    const data = {
      params: params,
      result: CreateConnectAccountPayoutSchema,
      type: "StripePayout"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createConnectAccountPayout", data).then(
        response => {
          resolve(response.data.createConnectAccountPayout);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // transactions report
  getTransactionsReport({ commit }, params) {
    const data = {
      params: params,
      result: TransactionReportPaginated
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("transactions", data).then(
          response => {
            resolve(response.data.transactions);
          },
          error => {
            reject(error);
          }
      );
    });
  },
};

export default actions;
