<template>
  <v-menu
    ref="opened"
    :close-on-content-click="false"
    v-model="opened"
    :nudge-right="40"
    :return-value.sync="model"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-model="computedDateFormatted"
        background-color="white"
        :label="label"
        :hint="hint"
        :outlined="outlined"
        :persistent-hint="persistentHint"
        append-icon="fal fa-calendar-alt"
        readonly
        v-on:click:append="opened = true"
        :error-messages="errorMessages"
        :data-vv-validate-on="dataVvValidateOn"
        :disabled="disabled"
        :placeholder="placeholder"
      >
      </v-text-field>
    </template>
    <v-date-picker
      ref="datePicker"
      v-model="model"
      :min="minDate"
      :max="maxDate"
      no-title
      scrollable
      :show-current="current"
      :first-day-of-week="1"
      locale="en-us"
      @change="onChange"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" v-if="resetButton" @click="reset()"
        >Clear</v-btn
      >
      <v-btn text color="primary" v-if="cancelButton" @click="cancel()"
        >Cancel</v-btn
      >
      <v-btn text color="primary" v-if="saveButton" @click="select()">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  $_veeValidate: {
    // value getter
    value() {
      return this.selected;
    },
    // name getter
    name() {
      return this.name;
    }
  },
  props: {
    value: {
      default: null
    },
    label: {
      required: true
    },
    minDate: {
      default: null
    },
    maxDate: {
      required: false,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    cancelButton: {
      type: Boolean,
      default: false
    },
    current: {
      type: Boolean,
      default: true
    },
    resetButton: {
      type: Boolean,
      default: false
    },
    saveButton: {
      type: Boolean,
      default: false
    },
    selectYearFirst: {
      required: false,
      type: Boolean,
      default: false
    },
    hint: {
      default: ""
    },
    persistentHint: {
      default: false
    },
    dataVvValidateOn: {
      required: false
    },
    errorMessages: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.model = val;
      }
    },
    model: function(val) {
      this.selected = val;
    },
    opened(value) {
      if (this.selectYearFirst) {
        value &&
          this.$nextTick(() => (this.$refs.datePicker.activePicker = "YEAR"));
      }
    }
  },
  data: () => ({
    opened: false,
    model: null
  }),
  methods: {
    reset() {
      this.$refs.opened.save(null);
      this.opened = false;
    },
    cancel() {
      this.opened = false;
    },
    select() {
      this.$refs.opened.save(this.model);
      this.$emit("change", this.model);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.model);
    },
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    onChange() {
      return !this.saveButton ? this.select : () => {};
    }
  }
};
</script>
