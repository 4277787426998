// VueX data store
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// old modules
import loading from "./modules/loading/store.js";
import snackbar from "./modules/snackbar/store.js";
import settings from "./modules/settings/store.js";
import subscriptions from "./modules/subscriptions/store.js";
// new modules
import core from "./modules/core/store.js";
import business from "./modules/business/store.js";
import influencer from "./modules/influencer/store.js";
import agency from "./modules/agency/store.js";
import moderator from "./modules/moderator/store.js";
import translations from "./modules/translations/store.js";
import media from "./modules/media/store.js";
import filters from "./modules/filters/store.js";

export default new Vuex.Store({
  // Making sure that we're doing
  // everything correctly by enabling
  // strict mode in the dev environment.
  strict: process.env.NODE_ENV !== "production",
  modules: {
    loading,
    snackbar,
    settings,
    subscriptions,
    agency,
    business,
    moderator,
    influencer,
    core,
    translations,
    media,
    filters
  }
});
