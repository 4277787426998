<template>
  <v-container fluid class="p-0 signup-template" :class="getActiveClass">
    <v-row no-gutters>
      <v-col
        md="4"
        class="d-none d-md-flex align-self-start img-fluid position-fixed"
      >
      </v-col>
      <v-col md="8" class="offset-md-4">
        <v-row class="p-4 p-lg-5">
          <v-col md="3">
            <a href="https://www.makeinfluence.com/">
              <img src="@/assets/logo.svg" alt="logo" />
            </a>
          </v-col>
          <v-col md="9">
            <div class="text-end login-link">
              Already have an account?<router-link to="/login">
                Log in
              </router-link>
            </div>
          </v-col>
        </v-row>
        <v-row class="p-2 p-lg-5">
          <v-col md="6" class="mx-auto sign-up-template">
            <div v-if="isInflueencer" class="text-center mb-15 text-md-left">
              <h1 class="h1-title mb-7 mb-lg-2">Create an influencer account.</h1>
              <p>
                Create greater influence. An honest platform for the next generation
                of influencers who are reinventing what it means to have influence
              </p>
            </div>
            <div v-else class="text-center mb-15 text-md-left">
              <h1 class="h1-title mb-7 mb-lg-2">Create a business account.</h1>
              <p>
                Get access to a platform where price and performance go 
                hand in hand to ensure transparency for both parties.
              </p>
            </div>
            <v-tabs
              background-color="transparent"
              fixed-tabs
              v-model="activeTab"
              class="pt-14 pt-md-0 custom-tabs"
            >
              <v-tabs-slider color="#013138"></v-tabs-slider>

              <v-tab
                v-for="item in items"
                :key="item.name"
                :to="item.route"
                active-class="custom-active"
              >
                {{ item.text }}
              </v-tab>
              <v-tab-item
                v-for="tab of items"
                :key="tab.name"
                :value="tab.route"
              >
                <keep-alive>
                  <router-view></router-view>
                </keep-alive>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mi_tracking from "../../helpers/mixins/mi_tracking";

export default {
  mixins: [mi_tracking],
  data: () => ({
    loaded: false,
    items: [
      {
        text: "I'm a business",
        name: "business",
        route: "/business-signup"
      },
      {
        text: "I'm an influencer",
        name: "influencer",
        route: "/influencer-signup"
      }
    ],
    activeTab: "/business-signup"
  }),
  computed: {
    isInflueencer() {
      return this.$route.path.includes("influencer");
    },
    getActiveClass() {
      return this.isInflueencer ? "influencer-template" : "business-template";
    }
  },
  watch: {
    $route: "afterLeave"
  },
  methods: {
    onLoaded() {
      this.loaded = true;
    },
    afterLeave() {
      this.loaded = false;
    }
  },
  created() {
    this.miTrackPageView();
  }
};
</script>

<style lang="scss">
.signup-template {
  &.business-template {
    background-color: $companyBg;
    .img-fluid {
      background-image: url("./../../assets/business_signup.svg");
      transition: all 0.2s ease-in-out;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .sign-up-template {
      position: relative;

      &:before {
        width: 94px;
        content: " ";
        background-image: url("./../../assets/component1.svg");
        position: absolute;
        right: -106px;
        top: 138px;
        height: 91px;
        transition: all 0.5s ease-in-out;
        opacity: 1;
        @media only screen and (max-width: 960px) {
          right: 2%;
          top: -18%;
        }
        @media only screen and (max-width: 600px) {
          right: 15px;
          top: 120px;
        }
      }
      &:after {
        width: 111px;
        content: " ";
        background-image: url("./../../assets/component2.svg");
        position: absolute;
        left: -166px;
        bottom: 80px;
        transition: all 0.5s ease-in-out;
        opacity: 1;
        height: 111px;
        @media only screen and (max-width: 960px) {
          left: 2%;
          bottom: 0;
        }
        @media only screen and (max-width: 600px) {
          left: 0;
          bottom: 0;
        }
      }
    }
  }
  &.influencer-template {
    background-color: $influencerBg;
    .img-fluid {
      background-image: url("./../../assets/influencer_signup.svg");
      transition: all 0.2s ease-in-out;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .sign-up-template {
      position: relative;
      &:before {
        width: 94px;
        content: " ";
        background-image: url("./../../assets/line.svg");
        position: absolute;
        right: -106px;
        top: 159px;
        height: 46px;
        transition: all 0.5s ease-in-out;
        opacity: 1;
        @media only screen and (max-width: 960px) {
          right: 2%;
          top: -18%;
        }
        @media only screen and (max-width: 600px) {
          right: 15px;
          top: 170px;
        }
      }
      &:after {
        width: 111px;
        content: " ";
        background-image: url("./../../assets/line2.svg");
        position: absolute;
        left: -166px;
        bottom: 80px;
        height: 111px;
        transition: all 0.5s ease-in-out;
        opacity: 1;
        @media only screen and (max-width: 960px) {
          left: 2%;
          bottom: 15%;
        }
        @media only screen and (max-width: 600px) {
          left: 0;
          bottom: 0;
        }
      }
    }
  }

  .custom-active {
    color: $text;
    text-transform: none;
    font-family: "HindBold", sans-serif;
  }
  .v-tab {
    color: $text;
    text-transform: none;
    font-size: 13px;
  }
  .v-tabs-items {
    background-color: transparent;
  }
}
.h1-title {
  font-size: 21px !important;
  color: $text;
  font-family: "HindBold", sans-serif !important;
}
.custom-tabs .v-tabs-bar__content{
  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}
</style>
