import { InfluencerSchema } from "./users.js";
import { CampaignSchema } from "./campaigns.js";

export const StatsSchema = `{
  data {
    clicks
    unique_clicks
    sales
    value
    hour
    commission
    conversion
    date
    influencer {
      uuid
      user{
        first_name
        last_name
      }
    }
    campaign{
      uuid
      name
      active
      min_followers
      max_followers
      cost_per_action_percent
      cost_per_action_fixed
      business{
        uuid
        display_name
      }
    }
    business {
      uuid
      company
      display_name
    }
  }
}`;
export const DashboardBusinessSchema = `{
  data {
    clicks
    unique_clicks
    sales
    value
    commission
    conversion
    business {
      uuid
      company
      display_name
      vat
      phone
      website
      description
      webshop_system
      heard_about_text
      heard_about
      managed_contract_url
      full_agency_service
      service_fee
      commission_invoice_cycle
      auto_charge_commission_invoice
      approval_days
      tracking_level
      pixel_approved_at
      promotion_code_tracking_approved_at
      created_at
      updated_at
      agency {
        uuid
        name
        created_at
        updated_at
      }
      category {
        id
        name
        display_name
        description
        icon
        created_at
        updated_at
      }
    }
  }
}`;
export const DashboardInfluencerSchema = `{
  data {
    clicks
    unique_clicks
    sales
    value
    commission
    conversion
    date
    influencer ${InfluencerSchema}
  }
}`;
export const DashboardCampaignSchema = `{
  data {
    clicks
    unique_clicks
    sales
    value
    commission
    conversion
    date
    campaign ${CampaignSchema}
  }
}`;
export const InfluencerDailySchema = `{
  id
  date
  followers
  impressions
  profile_views
  unique_profile_views
  website_clicks
  text_message_clicks
  has_extendend_analytics
  influencer_channel_id
  created_at
  updated_at
}`;
export const InfluencerCitySchema = `{
  id
  followers
  influencer_channel_analytic_id
  influencer_channel_city_id
  created_at
  updated_at
  deleted_at
  city {
    id
    name
    city_name
    region
    created_at
    updated_at
    deleted_at
  }
}`;
export const InfluencerCountrySchema = `{
  id
  followers
  country {
    id
    name
    full_name
    country_code
    capital
    citizenship
    currency
    currency_code
    currency_sub_unit
    currency_symbol
    currency_decimals
    iso_3166_2
    iso_3166_3
    region_code
    sub_region_code
    eea
    calling_code
    flag
    created_at
    updated_at
  }
  influencer_channel_analytic_id
  created_at
  updated_at
  deleted_at
}`;
export const InfluencerGenderAgeSchema = `{
  id
  followers
  age
  gender
  influencer_channel_age_gender_id
  influencer_channel_analytic_id
  created_at
  updated_at
  deleted_at
}`;
export const InfluencerStatsSchema = `{
  daily ${InfluencerDailySchema}
  cities ${InfluencerCitySchema}
  countries ${InfluencerCountrySchema}
  gender_age ${InfluencerGenderAgeSchema}
}`;
export const ComissionStatusSchema = `{
  data {
    new
    approved
    invoiced
    pending_payout
    business {
      uuid
      display_name
      user {
        uuid
        full_name
      }
    }
    influencer {
      uuid
      user {
        uuid
        first_name
        last_name
      }
    }
  }
}`;
export const ActiveUsersStatsSchema = `{
  influencer {
    total
    active
  }
  business {
    total
    active
  }
}`;
export const InfluencerCommissionStatuses = `{
    data {
      influencer_id
      influencer_name
      account_type
      is_taxable
      transfers_enabled_at
      payouts_enabled_at
      status_new
      status_approved
      status_invoiced
      status_paid
      legacy_invoiced
      legacy_paid
      status_transfer_ready
      tax_ready_total
      status_transfer_ready_total
      status_transfer_possible
      tax_possible_total
      status_transfer_possible_total
      balance
    }
    stripe_balance_available
}`;
export const LegacyStats = `{
    legacy_total
    legacy_paid
    legacy_unpaid
    stripe_available
}`;
export const LegacyStatsByInfluencer = `{
    influencer_id
    influencer_uuid
    influencer_name
    legacy_total
    legacy_paid
    legacy_unpaid
    legacy_next_transfer
    paid_installments
    avg_transactions_count
    last_month_transactions_count
    performance
}`;
export const InstagramReportSummerized = `{
    influencers
    channels
    posts
    feed_posts
    story_posts
    reach
    impressions
    likes
    comments
    saves
    engagement
}`;
export const InstagramReportInfluencers = `{
    data {
      influencer_id
      first_name
      last_name
      posts
      feed_posts
      story_posts
      reach
      impressions
      likes
      comments
      saves
      engagement
    }
}`;