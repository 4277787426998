export const ClicksPaginated = `{
    lastPage
    currentPage
    total
    limit
    data {
        uuid
        created_at
        ip
        influencer_channel {
            influencer {
            uuid
                user {
                    full_name
                }
            }
        }
        campaign {
            uuid
            name
            business {
                uuid
                display_name
            }
        }
        user_agent {
            is_robot
            device_size
            simple_parse {
                name
                device
                operating_system
            }
            operating_system {
                name
                version
            }
            browser {
                name
                version
            }
        }
    }
}`;