<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card height="100vh" style="overflow: hidden;">
      <v-toolbar color="secondary" class="no-shadow">
        <v-btn icon dark @click="showDialog = false">
          <v-icon>fal fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>
          Notifications
        </v-toolbar-title>
      </v-toolbar>
      <!--100% height minus toolbar height-->
      <v-container
        fluid
        class="p-0"
        style="height: calc(100vh - 56px); overflow: auto;"
      >
        <!--100% height minus toolbar height-->
        <notifications-list
          :loading="loading"
          :hasMore="hasMore"
          :menuLoaded="true"
          @scrollBottom="loadNotifications"
        ></notifications-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import NotificationsList from "./NotificationsList.vue";

export default {
  name: "TheNotificationDialog",
  components: { NotificationsList },
  data: () => ({
    loading: true,
    hasMore: true
  }),
  computed: {
    ...mapState("core/notifications", ["showNotificationsDialog"]),
    ...mapState("core/notifications", {
      notifications: state => state
    }),
    ...mapGetters("core/notifications", ["unreadNotifications"]),
    showDialog: {
      get() {
        return this.showNotificationsDialog;
      },
      set(newValue) {
        this.toggleNotificationsDialog(newValue);
      }
    }
  },
  methods: {
    ...mapActions("core/notifications", [
      "addNotification",
      "getNotifications",
      "updateUnreadNotifications",
      "readNotification"
    ]),
    ...mapMutations("core/notifications", ["toggleNotificationsDialog"]),
    loadNotifications() {
      if (!this.hasMore) {
        return false;
      }
      // Set loading
      this.loading = true;
      // Get params
      let params = {
        limit: 10
      };
      // Set last items created at to avoid getting duplicates when lazy loading
      if (this.notifications.notifications.length > 0) {
        // Get last array index
        let index = this.notifications.notifications.length - 1;
        // Use last created at for filter
        params.min_created_at = this.$moment(
          this.notifications.notifications[index].created_at
        ).format("YYYY-MM-DD HH:MM:SS");
      }
      this.getNotifications(params).then(
        notifications => {
          this.loading = false;
          // If limit is not the same as current response
          // There is no more items
          if (params.limit !== notifications.length) {
            this.hasMore = false;
          }
        },
        // error
        () => {
          this.loading = false;
        }
      );
    },
    closeModal() {
      let currentUnread = this.cloneDeep(this.unreadNotifications);
      // Mark all unread as read
      for (let i in currentUnread) {
        this.readNotification(currentUnread[i]);
      }
      const params = { unread: true, mark_all_read: true };
      this.getNotifications(params).then(notifications => {
        this.updateUnreadNotifications(notifications);
      });
    }
  },
  watch: {
    showNotificationsDialog() {
      document.querySelector("html").style.overflow = this
        .showNotificationsDialog
        ? "hidden"
        : null;
    },
    showDialog(newValue) {
      if (!newValue) {
        this.closeModal();
      }
    }
  },
  created() {
    this.loadNotifications();
  }
};
</script>
