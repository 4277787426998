export const InvoiceAccountSchema = `{
  email
  name
  phone
  address
  postal_code
  city
  country
  vat {
    value
    type
    verification
  }
}`;
export const InvoiceSchema = `{
  stripe_key
  invoice_key
  status
  paid
  forgiven
  date
  total
  view_url
  download_url
  type
  business {
    user{
      first_name
    }
    uuid
    display_name
  }
}`;
export const CreateInvoiceSchema = `{
  id
}`;
export const ForceInvoiceTransactionResponse = `{
  success
}`;
