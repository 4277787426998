<template>
  <v-form class="bg-transparent mt-15 pb-15 form-template influencer-form">
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" class="py-0" data-cy="influencer-first_name">
          <span class="input-label">
            First name
          </span>
          <v-text-field
            outlined
            min-height="34"
            background-color="white"
            v-model="firstName"
            placeholder="First name"
            name="first_name"
            v-validate="'required'"
            :error-messages="
              validation.first_name
                ? validation.first_name
                : errors.collect('first_name')
            "
            @keyup="validation.first_name = null"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0" data-cy="influencer-last_name">
          <span class="input-label">
            Last name
          </span>
          <v-text-field
            outlined
            v-model="lastName"
            background-color="white"
            placeholder="Last name"
            v-validate="'required'"
            name="last_name"
            :error-messages="
              validation.last_name
                ? validation.last_name
                : errors.collect('last_name')
            "
            @keyup="validation.last_name = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="influencer-country">
          <span class="input-label">
            Country
          </span>
          <v-select
            v-model="country"
            background-color="white"
            outlined
            name="country"
            placeholder="Country"
            :items="countries"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            :loading="!countries.length"
            :error-messages="
              validation.country
                ? validation.country
                : errors.collect('country')
            "
            @keyup="validation.country = null"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="influencer-email">
          <span class="input-label">
            Email
          </span>
          <v-text-field
            v-model="email"
            background-color="white"
            outlined
            name="email"
            type="email"
            placeholder="Email"
            v-validate="'required|email'"
            :error-messages="
              validation.email ? validation.email : errors.collect('email')
            "
            @keyup="validation.email = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="influencer-phone">
          <span class="input-label">
            Phone
          </span>

          <v-text-field
            background-color="white"
            v-model="phone"
            outlined
            name="phone"
            placeholder="Phone number"
            v-validate="{
              required: true,
              regex: /^(\+){0,1}[0-9]+$/,
              excluded: '00000000',
            }"
            :error-messages="
              validation.phone ? validation.phone : errors.collect('phone')
            "
            @keyup="validation.phone = null"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0" data-cy="influencer-password">
          <span class="input-label">
            Password
          </span>
          <v-text-field
            v-model="password"
            background-color="white"
            outlined
            type="password"
            name="Password"
            placeholder="Password"
            v-validate="'required|min:6'"
            :error-messages="errors.collect('Password')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="py-0 pb-4 ">
          <span class="input-label d-block">
            Gender
          </span>
          <v-btn-toggle v-model="gender" class="p-2 bg-white" id="gender">
            <v-btn
              width="80"
              small
              active-class="deepgreenbtn"
              class="text-none border-0 custom-btn"
            >
              Female
            </v-btn>
            <v-btn
              small
              width="80"
              active-class="deepgreenbtn"
              class="text-none border-0 custom-btn"
            >
              Male
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12" sm="6" class="py-0" data-cy="influencer-date">
          <span class="input-label">
            Birthday
          </span>

          <date-picker
            v-model="birthday"
            cancel-button
            name="Birthday"
            v-validate="'required'"
            :error-messages="errors.collect('Birthday')"
            save-button
            :current="false"
            outlined
            placeholder="DD/MM/ÅÅ"
            :maxDate="maxBirthDay"
            selectYearFirst
            label=""
            :reset-button="false"
          ></date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="py-0 m-0 custom-checkbox"
          data-cy="influencer-terms"
        >
          <v-checkbox
            v-model="terms"
            name="The terms"
            id="terms"
            color="#013138"
            v-validate="'required:true'"
            :error-messages="errors.collect('The terms')"
          >
            <template v-slot:label class="d-block">
              I accept&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://makeinfluence.com/dk/influencer/betingelser"
                @click.stop
                class="custom-link"
              >
                the terms
              </a>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-checkbox
            v-model="newsletter"
            color="#013138"
            label="I'd like to receive the newsletter"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="mr-4 mt-15 mx-auto" data-cy="influencer-submit">
        <v-btn
          class="mx-auto deepgreenbtn p-4"
          @click="save()"
          :loading="saveLoading"
          min-width="60"
        >
          Create account
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DatePicker from "@/components/common/filters/DatePicker";
import { mapActions } from "vuex";
import { getUserTypeRedirectUrl } from "../../helpers/get-user-type-redirect-url";
import mi_tracking from "../../helpers/mixins/mi_tracking";

export default {
  mixins: [mi_tracking],
  components: {
    DatePicker,
  },
  data: () => ({
    saveLoading: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: null,
    birthday: null,
    gender: 0,
    password: null,
    newsletter: false,
    terms: false,
    countries: [],
    country: null,
    validation: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      country: null,
    },
  }),
  computed: {
    maxBirthDay() {
      return this.$moment()
        .subtract(15, "years")
        .format("YYYY-MM-DD");
    },
  },
  methods: {
    ...mapActions("core", ["getCountries"]),
    ...mapActions("core/auth", ["registerInfluencer"]),
    loadActiveCountries() {
      this.getCountries({ active: true }).then(
        (countries) => {
          this.countries = countries;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveLoading = true;

          const params = {
            first_name: this.firstName,
            last_name: this.lastName,
            country_id: this.country,
            email: this.email,
            phone: this.phone.replace("+", ""),
            birth_day: Number(this.birthday.substring(8, 10)),
            birth_month: Number(this.birthday.substring(5, 7)),
            birth_year: Number(this.birthday.substring(0, 4)),
            gender: this.gender === 0 ? "female" : "male",
            password: this.password,
            newsletter: this.newsletter,
            terms: this.terms,
          };
          const loginParams = {
            username: this.email,
            password: this.password,
          };
          this.registerInfluencer(params).then(
            ({ data }) => {
              const redirectUrl = getUserTypeRedirectUrl("influencer");

              if (redirectUrl) {
                redirectUrl.searchParams.set(
                  "token",
                  data.registerInfluencer.payload.loginToken
                );
                redirectUrl.searchParams.set("shadow", "false");

                window.location.href = redirectUrl;

                return;
              }

              if (data.registerInfluencer.code === 200) {
                const url = window.location.pathname;
                if (this.$isProduction) {
                  window.fbq("trackCustom", "PageView", {
                    url: `${url}/success`,
                  });
                  window.fbq("track", "InfluencerLead");
                  this.miTrackConversion(
                    "inf-" + data.registerInfluencer.influencer_id
                  );
                }

                let redirectOptions = {
                  referral_type: this.$route.query.referral_type,
                  referral_value: this.$route.query.referral_value,
                };

                setTimeout(
                  () => this.signIn(loginParams, redirectOptions),
                  1000
                );
              }
            },
            (errors) => {
              const arr = this.$errorHandler.getValidationErrors(errors);
              this.validation = {
                email: this.$errorHandler.getErrorMessage(arr, "email"),
                phone: this.$errorHandler.getErrorMessage(arr, "phone"),
                first_name: this.$errorHandler.getErrorMessage(
                  arr,
                  "first_name"
                ),
                last_name: this.$errorHandler.getErrorMessage(arr, "last_name"),
                country: this.$errorHandler.getErrorMessage(arr, "country"),
              };
              this.setSnackError("Invalid information");
              this.saveLoading = false;
            }
          );
        }
      });
    },
  },
  mounted() {
    this.loadActiveCountries();
    if (this.$isProduction) {
      window.fbq("trackCustom", "InfluencerATC");
      window.fbq("trackCustom", "InfluencerViewContent");
    }
  },
  created() {
    this.firstName = this.$route.query.first_name;
    this.lastName = this.$route.query.last_name;
    this.email = this.$route.query.email;
    this.phone = this.$route.query.phone;
  },
};
</script>
<style lang="scss" scoped>
.form-template {
  .custom-btn {
    border-radius: 4px;
  }
  @media only screen and (max-width: 600px) {
    .v-btn-toggle {
      width: 100%;
      button {
        width: 50% !important;
      }
    }
  }
}
.custom-link {
  font-family: "HindRegular", sans-serif;
  color: #f3615a;
  text-decoration: none;
}
</style>
