<template>
  <div class="notification-list-wrapper">
    <v-list
      v-infinite-scroll="scrollBottom"
      two-line
      class="pa-0"
      infinite-scroll-disabled="disabledInfiniteScroll"
      infinite-scroll-distance="100"
    >
      <div v-for="item in orderedNotifications" :key="item.id">
        <!-- Header for display new/older headline(grouping) -->
        <v-divider
          v-if="item.header && item !== orderedNotifications[0]"
        ></v-divider>
        <v-subheader
          v-if="item.header"
          :key="item.header"
          class="grey lighten-3"
          style="height:30px"
          >{{ item.header }}</v-subheader
        >

        <v-divider v-if="!item.header"></v-divider>

        <v-list-item
          v-if="!item.header"
          :key="item.id"
          :class="!item.read_at ? 'light-blue lighten-5' : ''"
          @click="handleClick(item)"
        >
          <v-list-item-avatar>
            <v-avatar
              class="text-center"
              :color="getNotificationMeta(item).color"
            >
              <v-icon color="secondary" size="20">{{
                getNotificationMeta(item).icon
              }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="primary--text"
              v-html="getNotificationMeta(item).title"
            ></v-list-item-title>
            <v-list-item-subtitle class="caption">{{
              item.created_at | humanize_datetime
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <!--No notifications message-->
      <v-list-item v-if="orderedNotifications.length === 0 && !loading">
        <v-list-item-content class="text-center">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              No notifications
            </v-layout>
          </v-container>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="loading">
        <v-list-item-content class="text-center">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-progress-circular
                :size="32"
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-layout>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import NotificationMeta from "@/helpers/mixins/notification_meta";
import humanize_datetime from "@/helpers/filters/humanize_datetime";
import infiniteScroll from "vue-infinite-scroll";

export default {
  name: "NotificationsList",
  filters: { humanize_datetime },
  mixins: [NotificationMeta],
  directives: { infiniteScroll },
  props: {
    menuLoaded: Boolean,
    hasMore: Boolean,
    loading: Boolean
  },
  data: () => ({
    // data
  }),
  computed: {
    ...mapState("core/notifications", {
      showMobileDialog: "showNotificationsDialog",
      notifications: state => state
    }),
    ...mapGetters("core/notifications", ["orderedNotifications"]),
    disabledInfiniteScroll() {
      if (!this.menuLoaded || !this.hasMore || this.loading) {
        return true;
      }
      return false;
    }
  },
  watch: {
    '$route' () {
        this.$router.go(0);
    }
},
  methods: {
    ...mapActions("core/notifications", ["getNotification"]),
    ...mapMutations("core/notifications", ["toggleNotificationsDialog"]),
    handleClick(notification) {
      this.getNotification(Number(notification.id));
      let meta = this.getNotificationMeta(notification);
      if (meta.link) {
        if (this.showMobileDialog) {
          this.toggleNotificationsDialog(false);
        }

        this.$router.push(meta.link);
      }
    },
    scrollBottom() {
      this.$emit("scrollBottom");
    }
  }
};
</script>
