const languageFilePath = process.env.VUE_APP_TRANSLATIONS_URL;
const loadedLanguages = [];

const setLocale = (i18n, lang = "en") => {
    if (loadedLanguages.includes[lang]) {
        i18n.locale = lang;
        return;
    }

    fetch(`${languageFilePath}${lang}.json`).then(response => {
        if (!response.ok) {
            return console.warn(`Unable to load language: ${languageFilePath}${lang}.json`);
        }

        response.json().then(data => {
            i18n.setLocaleMessage(lang, data);
            loadedLanguages.push(lang);
            i18n.locale = lang;
        })
    });
}

export default setLocale;