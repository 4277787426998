export default {
  methods: {
    miTrackingInit() {
      if(window.MI !== undefined) {
        return;
      }

      (function(m,a,k,e,i,n,f) {
        m['MakeInfluenceObject']=i;m[i]=m[i]||{q:[]},
            // eslint-disable-next-line
            m[i]=new Proxy(m[i],{get:function(t,p,r){return t.hasOwnProperty(p)
                  ?t[p]:function(){m[i].q.push({n:p,a:arguments})}}}),n=a.createElement(k),
            f=a.getElementsByTagName(k)[0],n.async=1,n.src=e+'?'+Math.floor(new Date()
            /864e5);f.parentNode.insertBefore(n,f);
      })(window,document,'script','//scripts.makeinfluence.com/a.js', 'MI');

      // eslint-disable-next-line
      MI.set('business_id', '9bb4cc3c-5517-46b9-8385-e757e2801b28');
    },
    miTrackPageView() {
      this.miTrackingInit();

      // eslint-disable-next-line
      MI.send('pageview');
    },
    miTrackConversion(userId = "") {
      this.miTrackPageView();

      let cookieName = "_miid";

      function get_cookie(name){
        return document.cookie.split(';').some(c => {
          return c.trim().startsWith(name + '=');
        });
      }

      function deleteCookie(name, path = null, domain = null) {
        document.cookie = name + "=" +
            ((path) ? ";path="+path:"")+
            ((domain)?";domain="+domain:"") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
      }

      if( ! get_cookie( cookieName ) ) {
        return;
      }

      // eslint-disable-next-line
      MI.send('conversion', {
        unique_id: userId,
      });

      console.log("TRACKED");

      deleteCookie(cookieName);
    }
  }
};
