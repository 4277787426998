/* eslint-disable no-unused-vars */
// Computed state properties
import moment from "moment";
const getters = {
  isBusiness: state => {
    return localStorage.user_type && localStorage.user_type === "Business";
  },
  isInfluencer: state => {
    return localStorage.user_type && localStorage.user_type === "Influencer";
  },
  isModerator: state => {
    return localStorage.user_type && localStorage.user_type === "Moderator";
  },
  isAgency: state => {
    return localStorage.user_type && localStorage.user_type === "Agency";
  },
  isFinanceUser: state => {
    return localStorage.user_type 
      && localStorage.user_type === "Moderator"
      && (
        /^(.+)@makeinfluence\.com$/.test(state.user.email)
        || ['moderator1@demo.com'].includes(state.user.email)
      );
  },
  isManaged: state => {
    return (
      state.user !== null &&
      state.user.business &&
      state.user.business.agency &&
      state.user.business.agency_id !== null
    );
  },
  isFullyManaged: (state, getters) => {
    return getters.isManaged && state.user.business.full_agency_service;
  },
  activeChannels: state => {
    if (state.user === null || !state.user.influencer) {
      return [];
    }

    let influencer = state.user.influencer;

    // Return active channels
    return influencer.channels.filter(item => item.active);
  },
  missingChannels: (state, getters) => {
    // If none is active return true because they are "missing"
    if (getters.activeChannels.length < 1) {
      return true;
    }

    return false;
  },
  noChannelsApproved: (state, getters) => {
    for (let i = 0; i < getters.activeChannels.length; i++) {
      let channel = getters.activeChannels[i];

      if (channel.approved_at !== null) {
        return false;
      }
    }

    return true;
  },
  channelApiExpired: (state, getters) => {
    if(getters.checkExpiredApi) {
      const today = moment(new Date()).format('YYYY-MM-DD')
      const differ = getters.activeChannels.filter(item => item.api_failed_at)
      const expired = moment(differ[0].api_failed_at).format('YYYY-MM-DD');
      const start = moment(today, "YYYY-MM-DD");
      const end = moment(expired, "YYYY-MM-DD");
      return moment.duration(start.diff(end)).asDays();
    } else {
      return false
    }
  },
  checkExpiredApi: (state, getters) => {
    return getters.activeChannels.filter(item => item.api_failed_at).length > 0;
  },
  channelsMissingCategories: (state, getters) => {
    return getters.activeChannels.filter(item => item.categories.length < 3);
  },
  influencerActionRequired: (state, getters) => {
    let activePayoutAccounts = [];

    if(state.user && state.user.influencer) {
      activePayoutAccounts = state.user.influencer.payout_accounts.filter(
        item => item.active
      );
    }

    if (
      getters.missingChannels ||
      getters.noChannelsApproved ||
      getters.channelApiExpired >= 14 ||
      getters.channelsMissingCategories.length > 0 ||
      activePayoutAccounts.length <= 0
    ) {
      return true;
    }
    return false;
  },
  businessActionRequired: state => {
    if (state.user.business && !state.user.stripe_id && (! state.user.active_subscription || state.user.active_subscription.stripe_id !== 'shopify')) {
      return true;
    }

    return false;
  }
};

export default getters;
