export const themes = {
  influencer: {
    primary: "#013138",
    secondary: "#FFF2EB",
    accent: "#F7625B",
    success: "#2BE38B",
    error: "#FF1167",
    warning: "#FED744",
    greytxt: "#99A0A1",
    badges: "#4CB7C4"
  },
  moderator: {
    primary: "#013138",
    secondary: "#F2F2F8",
    accent: "#5959A6",
    success: "#2BE38B",
    error: "#FF1167",
    warning: "#FED744",
    greytxt: "#99A0A1",
    badges: "#4CB7C4"
  },
  agency: {
    primary: "#013138",
    secondary: "#EDF8F9",
    accent: "#4CB7C4",
    success: "#2BE38B",
    error: "#FF1167",
    warning: "#FED744",
    greytxt: "#99A0A1",
    badges: "#4CB7C4"
  },
  business: {
    primary: "#013138",
    secondary: "#EDF8F9",
    accent: "#4CB7C4",
    success: "#2BE38B",
    error: "#FF1167",
    warning: "#FED744",
    greytxt: "#99A0A1",
    badges: "#4CB7C4",
    teal: "#3AC2D2"
  }
};
