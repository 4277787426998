export function stringType(value) {
  return typeof value === "string";
}
export function arrayType(value) {
  return Array.isArray(value);
}
export function intType(value) {
  return Number.isInteger(value);
}
export function objectType(value) {
  return typeof value === "object" && value !== null;
}
