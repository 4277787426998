<template>
  <v-app-bar color="#fff" class="app-navbar" fixed app style="z-index: 9;">
    <v-app-bar-nav-icon
      color="primary"
      @click.stop="$emit('toggle-menu')"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <the-chats-menu v-if="chatVisible"></the-chats-menu>
    <the-notification-menu></the-notification-menu>

    <v-menu
      offset-y
      origin="center center"
      :nudge-bottom="17"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-badge
            class="status-badge"
            avatar
            bordered
            overlap
            :color="colors[status - 1]"
            v-if="user.influencer"
          >
            <user-avatar
              :user="user"
              text-color="white--text"
              background-color="primary"
              class="cursor-pointer"
            >
            </user-avatar>
          </v-badge>
          <user-avatar
            :user="user"
            text-color="white--text"
            background-color="primary"
            class="cursor-pointer"
            v-else
          >
          </user-avatar>
        </div>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-if="user.influencer"
          @click="changeStatus()"
          ripple="ripple"
          rel="noopener"
        >
          <div class="d-flex status-text">
            <div class="status-icon">
              <v-icon size="12px" v-if="status === 1" color="error">fas fa-circle</v-icon>
              <v-icon size="12px" v-if="status === 2" color="success">fas fa-circle</v-icon>
            </div>
            <v-list-item-content>
              <div v-if="status === 1">
                <v-list-item-title>Hide profile</v-list-item-title>
                <v-list-item-content class="light-grey">
                  Toggle 'hidden', so companies can't see you on the platform.
                </v-list-item-content>
              </div>
              <div v-if="status === 2">
                <v-list-item-title>Show profile</v-list-item-title>
                <v-list-item-content class="light-grey">
                  Toggle 'active', so companies can see you on the platform.
                </v-list-item-content>
              </div>
            </v-list-item-content>
          </div>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in menuItems"
          :to="{ name: item.link }"
          @click="item.click"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="index"
        >
          <div class="d-flex">
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapActions, mapState } from "vuex";
import UserAvatar from "../avatars/UserAvatar";
import TheNotificationMenu from "./notifications/TheNotificationMenu";
import TheChatsMenu from "./Chat/TheChatsMenu";

export default {
  components: { TheChatsMenu, TheNotificationMenu, UserAvatar },
  data() {
    return {
      status: 0,
      colors: ["success", "error"]
    };
  },
  computed: {
    ...mapState("core/auth", ["user"]),
    chatVisible() {
      if (this.user.moderator) {
        return false;
      }

      return true;
    },
    menuItems() {
      if (this.user.agency) {
        return [
          {
            icon: "fal fa-user-circle",
            title: "Profile",
            link: "profile",
            click: () => {}
          },
          {
            icon: "fal fa-sign-out-alt",
            title: "Logout",
            link: "logout",
            click: () => {}
          }
        ];
      } else if (this.user.business) {
        return [
          {
            icon: "fal fa-user-circle",
            title: "Profile",
            link: "profile",
            click: () => {}
          },
          {
            icon: "fal fa-sign-out-alt",
            title: "Logout",
            link: "logout",
            click: () => {}
          }
        ];
      } else if (this.user.influencer) {
        return [
          {
            icon: "fal fa-user-circle",
            title: "Profil",
            link: "profile",
            click: () => {}
          },
          {
            icon: "fal fa-sign-out-alt",
            title: "Logout",
            link: "logout",
            click: () => {}
          }
        ];
      } else if (this.user.moderator) {
        return [
          {
            icon: "fal fa-user-circle",
            title: "Profile",
            link: "profile",
            click: () => {}
          },
          {
            icon: "fal fa-sign-out-alt",
            title: "Logout",
            link: "logout",
            click: () => {}
          }
        ];
      }
      return [];
    }
  },
  methods: {
    ...mapActions("influencer", ["updateInfluencer"]),
    changeStatus() {
      const params = {
        uuid: this.user.influencer.uuid,
        status: this.status === 1 ? 2 : 1
      };
      this.updateInfluencer(params).then(
        data => {
          this.status = data.status;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  mounted() {
    if (this.user.influencer) {
      this.status = this.user.influencer.status;
    }
  }
};
</script>
<style lang="scss">
.app-navbar {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(#013138, 0.1) !important;
}
.status-text {
  max-width: 250px !important;
}
.status-icon {
  margin-top: 9px;
  margin-left: 5px;
  margin-right: 38px;
}
.status-badge .v-badge__badge {
  height: 12px !important;
  width: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  bottom: calc(100% - 10px) !important;
  left: calc(100% - 10px) !important;
}
</style>
