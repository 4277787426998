<template>
  <form id="payment-form">
    <div id="card-element">
      <!-- Elements will create input elements here -->
    </div>
    <!-- We'll put the error messages in this element -->
    <div
      v-show="error"
      id="card-element-errors"
      class="white--text p-2 red rounded mt-3"
      role="alert"
    ></div>
  </form>
</template>
<script>
export default {
  name: "PaymentCard",
  props: ["stripe"],
  data: () => ({
    options: {
      hidePostalCode: true,
      style: {
        base: {
          fontFamily: "Roboto,sans-serif"
        }
      }
    },
    error: null
  }),
  computed: {
    //
  },
  methods: {
    initCardForm() {
      // Set your publishable key: remember to change this to your live publishable key in production
      // See your keys here: https://dashboard.stripe.com/account/apikeys
      let elements = this.stripe.elements();
      let card = elements.create("card", this.options);
      card.mount("#card-element");
      card.on("change", event => {
        this.displayError(event);
        if (!event.error && event.complete) {
          this.$emit("cardChange", card);
        } else {
          this.$emit("cardChange", null);
        }
      });
    },
    displayError(event) {
      let el = document.getElementById("card-element-errors");
      if (event.error) {
        this.error = event.error.message;
        el.textContent = event.error.message;
      } else {
        this.error = null;
        el.textContent = "";
      }
    }
  },
  mounted() {
    this.initCardForm();
  }
};
</script>
<style lang="scss">
//
</style>
