/**
 * Our GraphQL API returns a user type as a string. This function
 * takes that string and returns a type-safe user type.
 *
 * @example
 * ```
 * const userType = getUserType('business')
 * // userType: 'business'
 * const userType = getUserType('influencer')
 * // userType: 'influencer'
 * const userType = getUserType('App\\Models\\Business')
 * // userType: 'business'
 * const userType = getUserType('App\\Models\\Influencer')
 * // userType: 'influencer'
 * ```
 *
 * @param userType - The user type input
 * @returns A type-safe user type
 */
export function getUserType(userType) {
  const userTypes = ["business", "influencer"];

  return userTypes.find((type) => {
    return userType.toLowerCase().includes(type);
  });
}

export function getUserTypeRedirectUrl(userType) {
  const userTypeRedirectDomains = {
    business: process.env.VUE_APP_REDIRECT_URL_BUSINESS,
    influencer: process.env.VUE_APP_REDIRECT_URL_INFLUENCER,
  };

  const redirectDomain = userTypeRedirectDomains[userType?.toLowerCase()];

  if (!redirectDomain) {
    return null;
  }

  const protocol = redirectDomain.includes("localhost") ? "http:" : "https:";
  const targetUrl = new URL(`${protocol}//${redirectDomain}`);

  if (
    targetUrl.hostname !== window.location.hostname ||
    targetUrl.port !== window.location.port
  ) {
    return targetUrl;
  }

  return null;
}
