<template>
  <div>
    <v-card class="mb-4">
      <v-toolbar color="error" dark flat dense cad>
        <v-toolbar-title class="subheading">Lost connection</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        We lost connection to one or several of your social channels.
        <br /><br />

        <b>Can't proceed? Check the following</b>
        <ul>
          <li>Is your profile public?</li>
          <li>Is it a company profile?</li>
          <li>Is your Instagram channel associated to a Facebook page?</li>
          <li>
            Read the guide:
            <a
              href="https://makeinfluence.com/dk/influencer/blog/guides/saadan-opretter-du-en-virksomhedsprofil-paa-instagram"
              target="_blank"
              >How to setup a business profile on Instagram</a
            >
          </li>
          <li>
            If you still experience issues, reach out to
            support@makeinfluence.com
          </li>
        </ul>
      </v-card-text>
    </v-card>

    <v-container fluid grid-list-lg class="pa-0">
      <v-layout wrap>
        <v-flex
          v-for="influencerChannel in apiExpiredChannels"
          :key="influencerChannel.uuid"
          lg4
          md6
          xs12
        >
          <v-card>
            <div class="p-3 text-center">
              <v-avatar :size="80">
                <img :src="influencerChannel.profile_image_url" />
              </v-avatar>
            </div>
            <v-card-text>
              <v-list two-line class="pa-0">
                <v-list-item>
                  <v-list-item-action>
                    <v-icon>{{
                      influencerChannel.channel.icon
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ influencerChannel.username }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      influencerChannel.channel.display_name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon>fal fa-users</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ influencerChannel.followers | local_numbers }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Followers</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon>fal fa-camera-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ influencerChannel.media_count }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Posts</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon>fal fa-link</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span
                        v-if="
                          influencerChannel.active &&
                            influencerChannel.api_failed_at
                        "
                        class="error--text"
                      >
                        Lost connection
                      </span>

                      <span
                        v-else-if="
                          influencerChannel.active &&
                            !influencerChannel.approved_at
                        "
                        class="amber--text"
                      >
                        Pending approval
                      </span>

                      <span v-else-if="!influencerChannel.active">
                        Not connected
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="#4267B2"
                class="white--text"
                large
                block
                :href="
                  influencerChannel.channel.login_link +
                    '?user_uuid=' +
                    user.uuid +
                    '&redirectUrl=' +
                    windowLink
                "
                :loading="loading"
                @click="loading = true"
              >
                <v-icon left>fab fa-facebook</v-icon> Connect with Facebook
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  filters: { local_numbers },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", ["activeChannels"]),
    apiExpiredChannels() {
      return this.activeChannels.filter(item => item.api_failed_at);
    },
    windowLink() {
      let props = this.$router.resolve({
        name: 'home',
      });

      return window.location.hostname + props.href;
    }
  }
};
</script>
