export const LanguagesSchema = `{
    uuid
    name
    short_name
    status
    untranslated {
      translation_key_uuid
      translation_key_key
      translated_value
      default_value
    }
    created_at
    updated_at
  }`;
export const TranslationAreasSchema = `{
    uuid
    name
    created_at
    updated_at
  }`;
export const TranslationKeysSchema = `{
    uuid
    key
    created_at
    updated_at
    translation_areas {
      uuid
      name
      created_at
      updated_at
    }
    translations {
      uuid
      value
      language ${LanguagesSchema}
      created_at
      updated_at
    }
  }`;
export const TranslationsSchema = `{
  response {
    translation_key_uuid
    translation_key_key
    translated_value
    default_value
    translation_areas ${TranslationAreasSchema}
  }
  lastPage
  currentPage
  total
  perPage
    
  }`;
export const EditTranslationsSchema = `{
    uuid
    value
    default_value
    created_at
    updated_at
}`;
export const TranslationSchema = `{
  uuid
  value
  default_value
  translationKey{
    uuid
    key
    created_at
    updated_at
    translation_areas{
      uuid
        name
    }
  translations {
    uuid
    value
    default_value
    language{
      uuid
      name
      short_name
      status
    }
    created_at
    updated_at
}
  }
  created_at
  updated_at
}`;
