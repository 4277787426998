<template>
  <div
    v-if="$vuetify.breakpoint.xsOnly"
    class="mr-4"
    @click="toggleNotificationsDialog(true)"
  >
    <v-badge overlap color="success" :value="notifications.unread > 0">
      <template v-if="notifications.unread > 0" v-slot:badge>{{
        notifications.unread
      }}</template>
      <v-icon
        size="28"
        class="animated cursor-pointer"
        color="primary"
        :class="notificationsButtonClass"
        >fal fa-bell</v-icon
      >
    </v-badge>
  </div>
  <v-menu
    v-else
    v-model="showNotificationsMenu"
    max-height="288px"
    max-width="250px"
    offset-y
    origin="center center"
    :nudge-bottom="18"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="mr-4">
        <v-tooltip bottom :disabled="!$vuetify.breakpoint.xsOnly">
          <template v-slot:activator="{ on }">
            <v-badge overlap color="success" :value="notifications.unread > 0">
              <template v-if="notifications.unread > 0" v-slot:badge>{{
                notifications.unread
              }}</template>
              <v-icon
                v-on="on"
                size="28"
                class="animated cursor-pointer"
                color="primary"
                :class="notificationsButtonClass"
                >fal fa-bell</v-icon
              >
            </v-badge>
          </template>
          <span>Notifications</span>
        </v-tooltip>
      </div>
    </template>
    <notifications-list
      :loading="loading"
      :hasMore="hasMore"
      :menuLoaded="notificationsMenuLoaded"
      @scrollBottom="loadNotifications"
    ></notifications-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import NotificationsList from "./NotificationsList.vue";
import { changeFavicon } from "@/helpers/functions/changeFavicon.js";

export default {
  components: { NotificationsList },
  data: () => ({
    showNotificationsMenu: false,
    notificationsMenuLoaded: false,
    loading: true,
    hasMore: true,
    interval: null,
    notificationsButtonClass: ""
  }),
  computed: {
    ...mapState("settings", ["settings"]),
    ...mapState("core/auth", ["user"]),
    ...mapState("core/notifications", {
      notifications: state => state
    }),
    ...mapGetters("core/notifications", [
      "unreadNotifications",
      "orderedNotifications"
    ]),
    ...mapGetters("core/auth", ["isBusiness", "isInfluencer"]),
    isShadowLogin() {
      return JSON.parse(this.$authHelper.getShadowLogin());
    }
  },
  watch: {
    showNotificationsMenu(newValue, oldValue) {
      // Mark all as read when menu closes
      // Wait before setting value so lazy loading doesn't start before menu is displayed to user
      setTimeout(() => {
        this.notificationsMenuLoaded = newValue;
      }, 250);
      if (oldValue === true && newValue === false) {
        this.markAllAsRead();
      }
    }
  },
  methods: {
    ...mapActions("core/notifications", [
      "updateUnreadNotifications",
      "addNotification",
      "readNotification",
      "getNotifications"
    ]),
    ...mapMutations("core/notifications", ["toggleNotificationsDialog"]),
    markAllAsRead() {
      // If no unread notifications do nothing
      if (this.notifications.unread === 0 || this.isShadowLogin) {
        return null;
      }
      this.getUnreadNotifications(true);
    },
    getUnreadNotifications(markAsRead = false) {
      let params = { unread: true };
      if (markAsRead) {
        params.mark_all_read = true;
        // Clone to new var because index will change as they get updated
        let currentUnread = this.cloneDeep(this.unreadNotifications);
        // Mark all unread as read
        for (let i in currentUnread) {
          this.readNotification(currentUnread[i]);
        }
      }
      this.getNotifications(params).then(notifications => {
        this.updateUnreadNotifications(notifications);
      });
    },
    loadNotifications() {
      if (!this.hasMore) {
        return false;
      }
      // Set loading
      this.loading = true;
      // Get params
      let params = {
        limit: 10
      };
      // Set last items created at to avoid getting duplicates when lazy loading
      if (this.notifications.notifications.length > 0) {
        // Get last array index
        let index = this.notifications.notifications.length - 1;
        // Use last created at for filter
        params.min_created_at = this.$moment(
          this.notifications.notifications[index].created_at
        ).format("YYYY-MM-DD HH:MM:SS");
      }
      this.getNotifications(params).then(
        notifications => {
          this.loading = false;
          // If limit is not the same as current response
          // There is no more items
          if (params.limit !== notifications.length) {
            this.hasMore = false;
          }
        },
        // error
        error => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    changeFaviconNotifications(unread) {
      // user have unread notifications
      if (unread) {
        if (this.isBusiness) {
          changeFavicon(this.$favicons.businessNotification);
        } else if (this.isInfluencer) {
          changeFavicon(this.$favicons.influencerNotification);
        }
      } else {
        if (this.isBusiness) {
          changeFavicon(this.$favicons.business);
        } else if (this.isInfluencer) {
          changeFavicon(this.$favicons.influencer);
        }
      }
    }
  },
  created() {
    // Get first 10 notifications
    this.loadNotifications();
    // Get total of unread
    this.getUnreadNotifications();
    let seconds = 30;
    // If app is local(Dev)
    if (this.settings.app_env === "local") {
      // Every 60min
      seconds = 60 * 60;
    }
    // Get unread notifications every X seconds
    this.interval = setInterval(() => {
      this.getUnreadNotifications();
    }, seconds * 1000);
    // Show attention effect if unread notifications
    setInterval(() => {
      if (this.notifications.unread > 0) {
        this.notificationsButtonClass = "";
        setTimeout(() => {
          this.notificationsButtonClass = "tada";
        }, 250);
        this.changeFaviconNotifications(true);
      } else {
        this.changeFaviconNotifications(false);
      }
    }, 7000);
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
