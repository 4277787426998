export const TransactionSchema = `{
  uuid
  date
  amount
  type
  status
  influencer {
    uuid
    user{
      full_name
    }
  }
  campaign {
    uuid
    name
  }
}`;

export const TransactionReportPaginated = `{
    lastPage
    currentPage
    total
    limit
    data ${TransactionSchema}
  }
`;