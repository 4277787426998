// Methods. NEVER update state data from an action/method
// Update state data with mutations
import * as type from "./mutation-types";
import { SubscriptionSchema } from "@/helpers/schemas/subscriptions/subscription";
import { SubscriptionProductSchema } from "../../../helpers/schemas/subscriptions/subscription";

const actions = {
  setSubscription({ commit }, subscription) {
    commit(type.SET_SUBSCRIPTION, subscription);
  },
  setShowSubscriptionDialog({ commit }, showing) {
    commit(type.SET_SUBSCRIPTION_DIALOG, showing);
  },
  fetchSubscription({ commit }) {
    const data = {
      result: SubscriptionSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getActiveSubscription", data).then(
        response => {
          commit(type.SET_SUBSCRIPTION, response.data.getActiveSubscription);
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  },
  fetchSubscriptionProducts({ commit }) {
    const data = {
      result: SubscriptionProductSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("subscriptionProducts", data).then(
        response => {
          commit(type.SET_SUBSCRIPTION_PRODUCTS, response.data.subscriptionProducts);
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createSubscription({ commit }, params) {
    const data = {
      params,
      result: SubscriptionSchema,
      type: "Subscriptions"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createSubscription", data).then(
        response => {
          commit(type.SET_SUBSCRIPTION, response.data.createSubscription);
          resolve(response.data.createSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
};

export default actions;
