<template>
  <div>
    <div class="title">
      Your channels are being approved 🥳
    </div>

    <p>
      We quality check all media connecting to the platform. And respond to all approvals
      within 24 hours.
    </p>
    <p>
      You'll receive an email when we've reviewed your channel.
    </p>

    In the meantime, you can sign up to our newsletter and have a look at our
    <a href="https://makeinfluence.com/dk/influencer/blog" target="_blank"
      >blog</a
    >.
    <br />

    <v-btn
      color="success"
      class="mt-4 mb-4"
      :href="
        'https://makeinfluence9024.activehosted.com/f/7?email=' +
          user.email +
          '&firstName=' +
          user.first_name +
          '&lastName=' +
          user.last_name +
          '&user_type=Influencer'
      "
      target="_blank"
    >
      Sign up to newsletter
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data: () => ({
    radioGroup: null
  }),
  computed: {
    ...mapState("core/auth", ["user"])
  },
  methods: {},
  created() {}
};
</script>

<style scoped></style>
