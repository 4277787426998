import * as type from "./mutation-types";

// Change state data
const mutations = {
  [type.SET_SUBSCRIPTION](state, payload) {
    state.subscription = payload;
  },
  [type.SET_SUBSCRIPTION_DIALOG](state, payload) {
    state.showSubscriptionDialog = payload;
  },
  [type.SET_SUBSCRIPTION_PRODUCTS](state, payload) {
    state.subscriptionProducts = payload;
  }
};

export default mutations;
