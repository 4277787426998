export const NotificationSchema = `{
    id,
    type,
    notifiable_type,
    notifiable_id,
    data,
    read_at,
    created_at,
    updated_at,
  }`;
