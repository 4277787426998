<template>
  <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon @click="toggleChatDialog(true)">
        <v-icon size="28" class="cursor-pointer" color="primary">
          fal fa-comment
        </v-icon>
      </v-btn>
    </template>
    <span>Conversations</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("core/chats", [
      "toggleChatDialog",
    ]),
  },
};
</script>
