import moment from "moment";
// Computed state properties
const getters = {
  unreadNotifications: state => {
    // Return all unread notifications
    return state.notifications.filter(item => !item.read_at);
  },
  readNotifications: state => {
    // Return all read notifications
    return state.notifications.filter(item => item.read_at);
  },
  orderedNotifications: state => {
    let data = JSON.parse(JSON.stringify(state.notifications));

    return data.sort(function(a, b) {
      if (moment(a.created_at).isAfter(b.created_at)) {
        return -1;
      }

      return 1;
    });
  }
};

export default getters;
