<template>
  <v-container fluid class="p-0 login-template influencer-template">
    <v-row no-gutters>
      <v-col
        md="4"
        class="d-none d-md-flex align-self-start  img-fluid position-fixed"
      >
      </v-col>
      <v-col md="8" class="offset-md-4">
        <v-row class="p-4 p-lg-5">
          <v-col md="3">
            <a href="https://www.makeinfluence.com/">
              <img src="@/assets/logo.svg" alt="logo" />
            </a>
          </v-col>
          <v-col md="9">
            <div class="text-end login-link">
              Don't have an account?
              <router-link to="/influencer-signup">Sign up now </router-link>
            </div>
          </v-col>
        </v-row>
        <v-row class="p-4 p-lg-5" v-if="!isPasswordForgotten">
          <v-col md="6" class="mx-auto">
            <div>
              <h1 class="mb-15 title">Log in</h1>
            </div>

            <v-form
              class="mt-15"
              v-if="!isPasswordForgotten"
              @keyup.enter.native="loginAction()"
            >
              <v-row>
                <v-col cols="12" class="py-0" id="email">
                  <span class="input-label">
                    Email
                  </span>
                  <v-text-field
                    outlined
                    background-color="white"
                    v-model="email"
                    type="email"
                    name="Email"
                    v-validate="'required|email'"
                    :error-messages="errors.collect('Email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0" id="password">
                  <div class="d-flex align-content">
                    <div class="input-label">
                      Password
                    </div>
                    <div class="login-link">
                      <a @click="isPasswordForgotten = true">
                        Forgot password?</a
                      >
                    </div>
                  </div>

                  <v-text-field
                    outlined
                    background-color="white"
                    v-model="password"
                    type="password"
                    name="Password"
                    v-validate="'required'"
                    :error-messages="errors.collect('Password')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-btn
                id="login-btn"
                @click="loginAction"
                :loading="saveLoading"
                class="mt-15 mx-auto deepgreenbtn"
                large
              >
                Log in
              </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="isPasswordForgotten" class="p-2 p-lg-5">
          <v-col md="6" class="mx-auto">
            <div>
              <h1 class="mb-15 title">Reset password</h1>
              <p>
                Forgot your password? It happens to the best of us. Type your email
                below, and we will promptly send a new one.
              </p>
            </div>
            <v-form v-on:submit.prevent="forgotPasswordAction()">
              <span class="input-label">
                Email
              </span>
              <v-text-field
                outlined
                background-color="white"
                v-model="email"
                type="email"
                name="Email"
                v-validate="'required|email'"
                :error-messages="errors.collect('Email')"
              ></v-text-field>
              <v-row class="mr-4 mt-15 mx-auto">
                <v-btn type="submit"
                :loading="saveLoading"
                
                 class="p-4 mx-auto deepgreenbtn" large >
                  Reset password
                </v-btn>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import mi_tracking from "../../helpers/mixins/mi_tracking";

export default {
  mixins: [mi_tracking],
  data: () => ({
    loaded: false,

    email: "",
    password: "",
    isPasswordForgotten: false,
    saveLoading: false
  }),
  watch: {
    $route: "afterLeave"
  },
  methods: {
    ...mapActions("core/auth", ["login", "forgotPassword"]),
    loginAction() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.saveLoading = true;
          const params = {
            username: this.email,
            password: this.password
          };
          this.signIn(params);
        }
      });
    },
    onLoaded() {
      this.loaded = true;
    },
    afterLeave() {
      this.loaded = false;
    },
    forgotPasswordAction() {
      let data = {
        email: this.email
      };
      this.saveLoading = true;

      this.forgotPassword(data).then(
        () => {
          this.isPasswordForgotten = false;
          this.setSnackSuccess("New password sent");
          this.saveLoading = false;
        },
        () => {
          this.setSnackError("Invalid information");
          this.saveLoading = false;
        }
      );
    }
  },
  created() {
    this.miTrackPageView();
  },
  mounted() {
    this.$nextTick(function() {
      this.afterLeave();
    });
  }
};
</script>

<style lang="scss" scoped>
.align-content {
  justify-content: space-between;
}
.title {
  font-size: 21px !important;
  font-family: "BeatriceExtraBold", sans-serif !important;
}
.login-template {
  position: relative;
  &.influencer-template {
    background-color: $influencerBg;
    .img-fluid {
      background-image: url("./../../assets/influencer_signin.svg");
      transition: all 0.2s ease-in-out;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    &:before {
      width: 108px;
      content: " ";
      background-image: url("./../../assets/component9.svg");
      position: absolute;
      right: 7%;
      top: 26%;
      height: 97px;
      transition: all 0.5s ease-in-out;
      @media only screen and (max-width: 600px) {
        right: 4%;
        top: 15%;
      }
    }
    &:after {
      width: 111px;
      content: " ";
      background-image: url("./../../assets/component10.svg");
      position: absolute;
      left: 40%;
      bottom: 0;
      height: 111px;
      transition: all 0.5s ease-in-out;
      @media only screen and (max-width: 600px) {
        left: 8%;
        bottom: 8%;
      }
    }
  }
}
.v-tabs {
  min-height: 293px;
}
.img-fluid {
  transition: all 0.5s ease;
}
</style>
