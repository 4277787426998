import {
  stringType,
  arrayType,
  intType,
  objectType
} from "../functions/defineType.js";

export default class StringBuilder {
  constructor() {}
  /*
    Parse every possible format to string.
    Used as helper for parse every value to string.
  */
  stringify(value) {
    if (stringType(value)) {
      // escape double "
      let string = value.split('"').join("\\" + '"');
      // allow new lines
      string = string.replace(/(\r\n|\n|\r)/gm, "\\n");
      return `"${string}"`;
    }
    if (arrayType(value)) {
      return `${this.arrayToString(value)}`;
    }
    if (objectType(value)) {
      return `${this.objectToString(value)}`;
    }
    if (intType(value)) {
      return `${value}`;
    } else {
      return `${value}`;
    }
  }
  arrayToString(value) {
    const str = value
      .map(item => {
        return `${this.stringify(item)}`;
      })
      .join(",");
    return `[${str}]`;
  }
  objectToString(value) {
    const str = Object.keys(value)
      .map(key => {
        return `${key}: ${this.stringify(value[key])}`;
      })
      .join(",");
    return `{${str}}`;
  }
}
