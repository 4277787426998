/* eslint-disable no-unused-vars */
import * as type from "./mutation-types";
import {
  SystemSettingsSchema,
} from "@/helpers/schemas/core/system.js";

// Methods. NEVER update state data from an action/method
// Update state data with mutations
const actions = {
  setSystemLoading({ commit }, payload = false) {
    setTimeout(function() {
      commit("updateSystemLoading", payload);
    }, 350);
  },
  getSystemSettings({ commit }) {
    const data = {
      params: null,
      result: SystemSettingsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("systemSettings", data).then(
        response => {
          resolve(response.data.systemSettings);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  setPageLoading({ commit }, loading) {
    commit(type.SET_PAGE_LOADING, loading);
  }
};

export default actions;
