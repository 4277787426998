import * as type from "./mutation-types";

// Methods. NEVER update state data from an action/method
// Update state data with mutations
const actions = {
  showSnackBar({ commit }, show = false) {
    commit(type.SHOW_SNACK, show);
  },
  _setSnack({ state, commit, dispatch }, snackObj) {
    // Is there already a snack showing??
    if (state.show) {
      // Remove current showing snackbar
      dispatch("showSnackBar", false);

      // Add new snackbar when the old one is gone
      setTimeout(function() {
        commit(type.SET_SNACK, snackObj);
      }, 150);
    } else {
      // No current snack? Show right away
      commit(type.SET_SNACK, snackObj);
    }
  },
  setSnackInfo({ dispatch }, text) {
    let snack = {
      text: text,
      color: "primary"
    };

    dispatch("_setSnack", snack);
  },
  setSnackSuccess({ dispatch }, text) {
    let snack = {
      text: text,
      color: "success"
    };

    dispatch("_setSnack", snack);
  },
  setSnackError({ dispatch }, text) {
    let snack = {
      text: text,
      color: "error"
    };

    dispatch("_setSnack", snack);
  }
};

export default actions;
