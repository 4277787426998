import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import da from "./locales/da.json";

Vue.use(VueI18n);

const messages = {
  en: en,
  da: da,
};

const i18n = new VueI18n({
  locale: "en", // Set your default language here
  fallbackLocale: "en",
  messages,
});

export default i18n;
