export const UpdateModeratorSalesSchema = `{
    uuid
    unique_key
    ip
    value
    commission
    params
    approved_at
    disapproved_at
    disapproved_note
    paid_at
    payout_at
    stripe_invoice_key
    stripe_transfer_key
    click_type
    created_at
    updated_at
    click{
        campaign{
            uuid
            name
            business{
                uuid
                display_name
            }
        }
        influencer_channel{
            uuid
            username
            description
            influencer{
                uuid
                birthday
                gender
                stripe_updated_at
                stripe_connect_key
                stripe_balance_available
                user{
                    uuid
                    email
                    first_name
                    last_name
                    full_name
                }
            }
            channel{
                uuid
                name
                display_name
                description
                icon
                created_at
                updated_at
            }
        }
    }
    promotion_code{
        code
        uuid
        business{
            uuid
            display_name
        }
        influencer{
            uuid
            user{
                uuid
                email
                first_name
                last_name
                full_name
            }
        }
    }
    business{
        uuid
        company
        display_name
    }
    influencer{
        uuid
        user{
            uuid
            email
            first_name
            last_name
            full_name
        }
    }
}`;
export const ModeratorSingleSubscriptionSchema = `{
    uuid
    stripe_id
    name
    stripe_status
    stripe_plan
    stripe_plan_name
    quantity
    trial_ends_at
    ends_at
    product
    display_name
    company
    next_period_at
    monthly_recurring_revenue
    canceled_at
    created_at
    cancel_reason
    cancel_reason_text
    interval
    default_tax_rates {
        stripe_tax_rate_id
        active
        display_name
        percentage
    }
    collection_method
    days_until_due
    schedule {
        key
        status
        current_phase {
            start_date
            end_date
        }
        phases {
            start_date
            end_date
            trial_end
            plans {
                key
                nickname
                product {
                    key
                    name
                }
                amount
                currency
                interval
                interval_count
            }
        }
    }
    user {
        uuid
        email
        first_name
        last_name
        business{
            uuid
            company
            display_name
        }
    }
    stripe_discount {
        stripe_discount_id
        stripe_coupon_id
        stripe_customer_id
        stripe_subscription_id
        created_at
        stripe_coupon {
            stripe_coupon_id
            name
        }
    }
}`
export const TaxRatesSchema = `{
    stripe_tax_rate_id
    active
    country
    created
    description
    display_name
    inclusive
    jurisdiction
    percentage
    state
    subscription {
        uuid
        stripe_id
    }
}`

export const ModeratorSubscriptionsSchema = `{
    response {
        stripe_id
        name
        stripe_status
        stripe_plan
        stripe_plan_name
        quantity
        trial_ends_at
        ends_at
        product
        display_name
        company
        next_period_at
        monthly_recurring_revenue
        canceled_at
        created_at
        cancel_reason
        cancel_reason_text
        interval
        tax
        collection_method
        user {
            uuid
            email
            first_name
            last_name
            business{
                uuid
                company
                display_name
            }
        }
    }
    lastPage
    currentPage
    total
    perPage
    exportLink
}`

export const SubscriptionByStripeKeySchema = `{
    id
    customer
    discount{
        coupon
        object
        amount_off
        created
        currency
        duration
        duration_in_months
        livemode
        max_redemptions
        name
        percent_off
        redeem_by
        times_redeemed
        valid
    }
    current_period_start
    current_period_end
    collection_method
    cancel_at_period_end
    canceled_at
    cancel_at
    ended_at
    quantity
    trial_start
    trial_end
    status
    schedule{
        key
        status
        phases{
            start_date
            end_date
            plans{
                key
                nickname
                amount
                currency
                interval
                interval_count
            }
        }
    }
    plan{
        key
        nickname
        product{
            key
            name
        }
        amount
        currency
        interval
        interval_count
    }
    items{
        object
        url
        has_more
    }
}`;
export const SalesSummaryStatusesSchema = `{
    total
    approved
    paid
    invoiced
    disapproved
    new
    total_count
    approved_count
    paid_count
    invoiced_count
    disapproved_count
    new_count
}`;
export const bulkRejectSaleSchema = `{
    status
    total
}`;
