var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:[{ 'elevation-0 bordered': _vm.bordered }]},[_c('div',{staticClass:"p-3 text-center"},[_c('v-avatar',{attrs:{"size":80}},[_c('img',{attrs:{"src":_vm.influencerChannel.profile_image_url}})])],1),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.influencerChannel.channel.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.influencerChannel.username)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.influencerChannel.channel.display_name))])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v("fal fa-users")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("local_numbers")(_vm.influencerChannel.followers))+" ")]),_c('v-list-item-subtitle',[_vm._v("Followers")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v("fal fa-camera-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.influencerChannel.media_count)+" ")]),_c('v-list-item-subtitle',[_vm._v("Posts")])],1)],1),_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v("fal fa-link")])],1),_c('v-list-item-content',[_c('v-list-item-title',[(
                  _vm.influencerChannel.active && _vm.influencerChannel.api_failed_at
                )?_c('span',{staticClass:"error--text"},[_vm._v(" Lost connection ")]):(
                  _vm.influencerChannel.active && !_vm.influencerChannel.approved_at
                )?_c('span',{staticClass:"amber--text"},[_vm._v(" Pending approval ")]):(!_vm.influencerChannel.active)?_c('span',[_vm._v(" Not connected ")]):_vm._e()]),_c('v-list-item-subtitle',[_vm._v("Status")])],1)],1)],1)],1),_c('v-card-actions',[(_vm.influencerChannel.declined_at)?_c('v-btn',{attrs:{"color":"primary","block":"","disabled":""}},[_vm._v(" Previously rejected media, try again later ")]):(
          !_vm.influencerChannel.active && _vm.influencerChannel.followers < 1000
        )?_c('v-btn',{attrs:{"color":"primary","outlined":"","block":"","href":"https://www.makeinfluence.com/da/blog/guides/faa-flere-foelgere","target":"_blank"}},[_vm._v(" Get more followers ")]):_c('v-btn',{attrs:{"color":"primary","block":"","disabled":(_vm.influencerChannel.active && !_vm.influencerChannel.approved_at) ||
            _vm.channelActivated},on:{"click":function($event){_vm.showConnectDialog = true}}},[_vm._v(" "+_vm._s(_vm.saveButtonText)+" ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showConnectDialog),callback:function ($$v) {_vm.showConnectDialog=$$v},expression:"showConnectDialog"}},[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v(" Connect "),_c('b',[_vm._v(_vm._s(_vm.influencerChannel.username))])]),_c('div',{staticClass:"subtitle-1 light-grey"},[_vm._v(" Choose the categories, that best describe the content on "+_vm._s(_vm.influencerChannel.username)+" ")])])]),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",attrs:{"two-line":""}},_vm._l((_vm.categories),function(category){return _c('v-list-item',{key:category.id,on:{"click":function($event){return _vm.toggleCategory(category)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(category.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(category.display_name)+" ")]),_c('v-list-item-subtitle',[(_vm.hasCategory(category))?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fal fa-check")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("fal fa-times")])],1)],1)],1)}),1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(
            _vm.influencerChannel.categories &&
              _vm.influencerChannel.categories.length === 3
          )?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Connect media ")]):_c('v-btn',{attrs:{"disabled":""}},[_vm._v(" Choose 3 categories ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }