export default class ErrorHandler {
  vue = null;
  constructor(vue) {
    this.vue = vue;
  }
  /*
    Get all validation errors from error response.
    Used as helper for handle error.
  */
  getValidationErrors(errors) {
    // test if has graphql errors.
    let gql = errors.filter(error => {
      return error.category === "graphql";
    });
    if (gql && gql.length > 0) {
      let arr = gql.map(item => {
        if (item.errors[0].field === "other") {
          item.errors[0].message = "Invalid value";
          return item.errors[0];
        } else {
          return item.errors[0];
        }
      });
      return arr;
    } else {
      // test if has validation errors.
      let arr = errors.find(error => {
        return error.category === "validation";
      });
      return arr ? arr.errors : [];
    }
  }
  /*
    Get error message by field name.
    Used as helper for handle error.
  */
  getErrorMessage(errors, name) {
    const error = errors.find(item => {
      return item.field === name;
    });
    return error ? error.message : false;
  }
}
